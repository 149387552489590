import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';
import {CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class FinAccountComponent implements OnInit {

	paymentAmount;
	paymentModal = false;
	SMSCode;
	paymentSMS = false;
	paymentId;
	userBalance = 0;
	country;

	balanceList;
	debitList;
	formattedAmount;
	payment;
	aggregators;
	showH = false;
	showY = false;
	balanceDict;
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public CurrencyPipe: CurrencyPipe) {
	}

	ngOnInit() {
		this.checkCountry();
		this.getBalanceList();
		this.getBalance();
		this.getDebit();
		this.getAggregatorList();
	}

	checkCountry() {
	let user = JSON.parse(window.localStorage.getItem('user'));
		if(user.country === 'KZ') {
			this.country = 'тг';
		} else if (user.country === 'KG') {
			this.country = 'сом';
		} else if (user.country === 'RU') {
			this.country = 'рублей';
		}
	}

	transformAmount(element){
		// this.paymentAmount = this.paymentAmount.replace(/ /g,'');
	 //    this.paymentAmount = element.target.value.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ');
	    // this.formattedAmount = this.currencyPipe.transform(this.paymentAmount, 'USD')
	    // element.target.value = this.formattedAmount;
	}

	paymentConfirm(){
		this.app.balanceRequest(this.paymentAmount).subscribe(res => {
			this.paymentModal = false;
			this.paymentAmount = null;
			this.paymentSMS = true;
			this.paymentId = res.activation.id;
		})
	}

	getBalance(){
		this.app.getUserByToken().subscribe(res => {
			this.balanceDict = res.user.fin_institute.balance_dict;
			this.userBalance = res.user.fin_institute.total_aggregator_balance;
		})
	}

	getDebit(){
		this.app.get('/core/fin_institute/balance_operation/?only_aggregator=true&operation_type=CREDIT&limit=5').subscribe(res =>{
			this.debitList = res.results;
			this.showH = !this.debitList.length;
		})
	}

	onCodeEnter(){
		this.app.enterCodeSMS(this.paymentId, this.SMSCode).subscribe(res => {
			this.paymentSMS = false;
			this.getBalanceList();
		})
	}

	getAggregatorList(){
		this.app.getAggregatorList().subscribe(res => {
			this.aggregators = res;
		})
	}

	getBalanceList(){

		this.app.get('/core/fin_institute/balance_operation/?only_aggregator=true&operation_type=ALLOCATION&limit=5').subscribe(res => {
			this.balanceList = res.results;
			this.showY = !this.balanceList.length;
		})
	}

	changeAggr(item){
		if(item !== 'all'){
			let v = this.findObjectByKey(this.balanceDict, 'name', item);
			if(v){
				this.userBalance = v.balance;
			}else{
				this.userBalance = 0;
			}
		}else{
			this.getBalance();
		}
	}

	findObjectByKey(array, key, value) {
	    for(let item of Object.keys(array)){
	        if (array[item][key] === value) {
	            return array[item];
	        }
	    }
	    return null;
	}
}