import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';

@Component({
  selector: 'app-visions-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class GeneratorVisionsShowComponent implements OnInit {

	id;
	vision;
	comment;
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.id = this.route.snapshot.params['id'];
		this.getVision();
	}

	getVision(){
		this.app.get('/core/lead_generator/vision/' + this.id).subscribe(res => {
			this.vision = res;
			console.log(res)
		})
	}

	

	changeStatus(status){
		let data = {
			vision : this.vision.id,
			status: status,
			comment: this.comment
		}
		this.app.post('/core/lead_generator/vision/set_status/',data).subscribe(res => {
			this.getVision();
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

}
