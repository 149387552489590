import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-settings-bank',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class BankSettingsComponent implements OnInit {

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
  }

}