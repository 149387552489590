import {Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-revisionshow',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.css']
})
export class AnalystRevisionComponent implements OnInit {

	form: FormGroup;
	loading: boolean = false;
	@ViewChild('fileInput*') fileInput: ElementRef;
	
	id;
	revision;
	changingIds = [];
	leads;
	current = Date.now();
	editAmount = false;
	leadAmount;
	country;
	uploadModal = false;
	file;
	// LEAD_ACCEPTED
	// LEAD_REJECTED

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private fb: FormBuilder,
	      		public msg: MessageService) {
		this.createForm();
	}

	ngOnInit() {
		this.id = this.route.snapshot.params['id'];
		this.getList();
	}

	createForm() {
		this.form = this.fb.group({
			file: [null,Validators.required],
			revision: [null,Validators.required]
		});
	}

	private prepareSave(): any {
		let input = new FormData();
		input.append('file', this.form.get('file').value);
		input.append('revision', this.id);
		return input;
	}

	onFileChange(event) {
	    if(event.target.files.length > 0) {
	      let file = event.target.files[0];
	      this.form.get('file').setValue(file);
	      // console.log(this.form.get('file').value.name)
	      this.file = this.form.get('file').value.name;
	    }
	}

	clearFile() {
		this.form.get('file').setValue(null);
		this.file = null;
		// this.fileInput.nativeElement.value = '';
	}

	onSubmitf() {
		const formModel = this.prepareSave();
		this.loading = true;		
		this.app.uploadFile('/core/analyst/revision/upload_leads/',formModel).subscribe(res => {
			this.clearFile();
			this.loading = false;
			this.uploadModal = false;
			this.getList();
		}, error => {
			this.msg.addToast( error.message, 'error');
			this.loading = false;
		})
	}

	checkCountry(country) {
		if(country === 'KZ') {
		  this.country = 'тг';
		} else if (country === 'KG') {
		  this.country = 'сом';
		} else if (country === 'RU') {
		  this.country = 'рублей';
		}
	}

	onChangePayment(){
		let opt = {
			amount: this.leadAmount
		}
		this.app.post('/core/analyst/revision/' + this.id + '/pick_leads/', opt).subscribe(res => {
			this.getList();
			this.editAmount = false;
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	getList(){
		this.app.get('/core/analyst/revision/' + this.id).subscribe(res => {
			this.revision = res;
			this.leads = res.leads;
			// this.getLeads(this.revision.program.id);
		})
	}

	changeLeads(id){
		var index = this.changingIds.indexOf(id);
		if(index === -1){
			this.changingIds.push(id);
		}else{
			this.changingIds.splice(index,1);
		}
	}

	deleteLead (id) {
		let param = {
			revision: id,
			leads: this.changingIds
		}
		this.app.post('/core/analyst/revision/delete_lead_from_revision/', param).subscribe(res => {
			this.getList();
			this.changingIds = [];
		})
	}

	changeStatus(status){
		this.app.changeStatusAnalyst(status, this.changingIds).subscribe(res => {
			this.getList();
			this.changingIds = [];
		})
	}

	changeStatusAccept(status){
		let settings = {
			status: status
		}
		this.app.put('/core/analyst/revision/' + this.id + '/', settings).subscribe(res => {
			this.getList();
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	recalculateRevision(id) {
		let params = {
			revision: id
		}
		this.app.post('/core/analyst/revision/recalculate_revision/', params).subscribe(res => {
			this.getList();
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	getLeads(id){
		this.app.get('/core/analyst/lead/?only_aggregator=false&program='+id).subscribe(res =>{
			this.leads = res;
		})
	}

}