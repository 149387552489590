import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {CurrencyPipe } from '@angular/common';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class FinanceAccountComponent implements OnInit {

	paymentAmount;
	paymentModal = false;
	SMSCode;
	paymentSMS = false;
	paymentId;
	userBalance = 0;

	balanceList;
	debitList;
	formattedAmount;
	payment;
	showH = false;
	showF = false;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public CurrencyPipe: CurrencyPipe,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.checkCountry();
		this.getBalanceList();
		this.getBalance();
		this.getDebit();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	transformAmount(element){

	}

	paymentConfirm(){
		this.app.post("/core/aggregator/balance_request/",{amount:this.paymentAmount}).subscribe(res => {
			this.paymentModal = false;
			this.paymentAmount = null;
			this.paymentSMS = true;
			this.paymentId = res.activation.id;
		})
	}

	getBalance(){
		this.app.getUserByToken().subscribe(res => {
			this.userBalance = res.user.aggregator.balance;
		})
	}

	getDebit(){
		this.app.get('/core/aggregator/balance_operation/?operation_type=CREDIT&only_aggregator=true').subscribe(res =>{
			this.debitList = res.results;
			this.showH = !res.results.length;
		})
	}

	onCodeEnter(){
		this.app.post("/auth/activation/"+this.paymentId+'/activate/',{code:this.SMSCode }).subscribe(res => {
			this.paymentSMS = false;
			this.getBalanceList();
		}, error => {
			this.msg.addToast(error.message,'error')
		})
	}

	getBalanceList(){
		this.app.get('/core/aggregator/balance_request/?limit=5&only_aggregator=true').subscribe(res => {
			this.balanceList = res.results;
		})
	}
}