import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';


@Component({
  selector: 'app-show-bank',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class GeneratorShowProgramComponent implements OnInit {
	id;
	program;
	leads;
	expanded = false;
	country;
	visions= [];
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
	}

	ngOnInit() {
		this.checkCountry();
		this.id = this.route.snapshot.params['id'];
		this.app.get('/core/lead_generator/program/' + this.id).subscribe(res => {
			this.program = res;
			this.getLeads(res.id);
			this.getImage(this.program.visions)
		})
	}

	toggle(){
	     this.expanded = !this.expanded;
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getLeads(id){
		this.app.get('/core/lead_generator/lead/?only_aggregator=false&program='+id).subscribe(res =>{
			this.leads = res.results;
		})
	}

	getImage(arr){
		for(let item of arr){
			this.app.get('/core/lead_generator/vision/' + item.id).subscribe(res => {
				this.visions.push(res)
			})
		}
	}
}