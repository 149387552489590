import { Injectable } from '@angular/core';
import { Router, ActivatedRoute,  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
	returnUrl: string;
    constructor(private router: Router,
    	 		private route: ActivatedRoute,) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('auth_key') && localStorage.getItem('user')) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
