import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';


@Component({
  selector: 'app-distribution_of_funds',
  templateUrl: './distribution_of_funds.component.html',
  styleUrls: ['./distribution_of_funds.component.css']
})

export class AggregatorDistributionOfFundsComponent implements OnInit {
	
	link = '/core/aggregator/balance_operation/?only_aggregator=true&operation_type=ALLOCATION&limit=1000';
	showH = false;
	list;
	programs;
	programId = '';
	country;
  	filtering = {
	    program: 'all',
	    date_created_begin: '',
	    date_created_end: '',
	    status: ''
	}

	reseting = {
		program: 'all',
	    date_created_begin: '',
	    date_created_end: '',
	    status: ''	
	}

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		this.getList();
	}

	ngOnInit() {
		this.checkCountry();
		this.app.get('/core/aggregator/program/').subscribe(res =>{
			this.programs = res;
		})
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	reset(){
		this.reseting = {
		    program: 'all',
		    date_created_begin: '',
		    date_created_end: '',
		    status: ''
		}
		this.filtering = {
		    program: 'all',
		    date_created_begin: '',
		    date_created_end: '',
		    status: ''
		}
		this.getList();
	}

	getList(){
		let filter = '';
	    for(let key of Object.keys(this.filtering)){
	      if(this.filtering[key]){
	      	if(this.filtering[key] !== 'all'){
		        filter += '&' + key + '=' + this.filtering[key];
		    }
	      }
	    }

		this.app.get(this.link + filter).subscribe(res => {
			this.showH = !res.results.length;
			this.list = res.results;
		})
	}

  	onchangeDate(val, type){
		if(val){
		  this.filtering[type] = new Date(val).toISOString();
		}else {
		  this.filtering[type] = '';
		}
		this.getList();
	}

	onSelectProgram(val){
		if(val === 'all'){
			this.filtering.program = '';
		}else{
			this.filtering.program = val;
		}
		this.getList();
	}

	doExcel() {
		let filter = '';
	    for(let key of Object.keys(this.filtering)){
	      if(this.filtering[key]){
	      	if(this.filtering[key] !== 'all'){
		        filter += '&' + key + '=' + this.filtering[key];
		    }
	      }
	    }

	    let settings = "/core/aggregator/balance_operation/export/?only_aggregator=true&operation_type=ALLOCATION&limit=1000";

	    this.app.getReportExcel(settings+filter).subscribe(data => {
	        this.downloadFile(data._body);
	    });
  	}
	
	downloadFile(data) {
		let today: number = Date.now();
	    let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
	    var blobURL = window.URL.createObjectURL(blob);
	    var anchor = document.createElement("a");
	    anchor.download = "report"+today+".xls";
	    anchor.href = blobURL;
	    anchor.click();
  	}


}