import {Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-revision_history',
  templateUrl: './revision_history.component.html',
  styleUrls: ['./revision_history.component.css']
})
export class AggrRevisionHistoryComponent implements OnInit {
	
	list;

	modalCSV = false;
	showH = false;

	form: FormGroup;
  	loading: boolean = false;

  	@ViewChild('fileInput') fileInput: ElementRef;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private fb: FormBuilder) {
		this.createForm();
	}

	ngOnInit() {
		this.getReviosion();
	}

	getReviosion(){
		this.app.get('/core/fin_institute/revision/?only_aggregator=true&limit=1000').subscribe(res => {
			this.list = res.results;
			this.showH = !res.results.length;
		})
	}

	createForm() {
	    this.form = this.fb.group({
	      file: [null,Validators.required]
	    });
	  }


	private prepareSave(): any {
		let input = new FormData();
		input.append('file', this.form.get('file').value);
		return input;
	}

  	onFileChange(event) {
	    if(event.target.files.length > 0) {
	      let file = event.target.files[0];
	      this.form.get('file').setValue(file);
	    }
	}

	onSubmit() {
		const formModel = this.prepareSave();
		this.loading = true;
		// In a real-world app you'd have a http request / service call here like
		// this.http.post('apiUrl', formModel)
		this.loading = false;
		
		this.app.uploadCSV(formModel).subscribe(res => {
			this.getReviosion();
			this.clearFile();
			this.modalCSV = false;
		})
	}

	clearFile() {
		this.form.get('file').setValue(null);
		this.fileInput.nativeElement.value = '';
	}


	doExcel() {
		let filter = '';
	    let settings = "/core/fin_institute/revision/export/?only_aggregator=true&limit=1000";

	    this.app.getReportExcel(settings+filter).subscribe(data => {
	        this.downloadFile(data._body);
	    });
  	}
	
	downloadFile(data) {
		let today: number = Date.now();
	    let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
	    var blobURL = window.URL.createObjectURL(blob);
	    var anchor = document.createElement("a");
	    anchor.download = "report"+today+".xls";
	    anchor.href = blobURL;
	    anchor.click();
  	}


}