import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';


@Component({
  selector: 'app-list-bank',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class BankListCompaignComponent implements OnInit {
	
	list;
	showH;
	country;
	offset = 0;	
	page = 1;
	collectionSize = '';
	limit = 15;
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
		this.checkCountry();
		this.getProgram();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getProgram();
		}
	}

	getProgram(){
		this.offset = (this.page - 1) * this.limit;
		this.app.get('/core/bank_aggregator/campaign/?limit=' + this.limit + '&offset=' + this.offset).subscribe(res => {
			this.list = res.results;
			this.showH = !res.results.length;
			this.collectionSize = res.count;
		})
	}

	deleteProgram(id){
		this.app.deleteProgram(id).subscribe(res => {
			this.getProgram();
		})
	}

}