import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';


@Component({
  selector: 'app-visions-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class BankVisionsAcceptedComponent implements OnInit {

	list;
	offset = 0;	
	page = 1;
	collectionSize = '';
	limit = 15;
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
	}

	ngOnInit() {
		this.getVisions();
	}

	getVisions(){
		this.offset = (this.page - 1) * this.limit;
		let url = '/core/bank_aggregator/vision/?limit=' + this.limit + '&offset=' + this.offset + '&status=APPROVED'; 
		this.app.get(url).subscribe(res => {
			this.list = res.results;
			this.collectionSize = res.count;
		})
	}

	loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getVisions();
		}
	}

}