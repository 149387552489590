import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class BankAggrEditComponent implements OnInit {
	
	cities;
	leadFields;
	leadSource;
	id;

	categories;

	confg;
	additionalSources = "";

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.id = this.route.snapshot.params['id'];
		this.app.get('/core/bank_aggregator/program/' + this.id).subscribe(res => {
			let b = {
				categories: res.categories,
				cities: res.cities,
				date_end: res.date_end,
				date_start: res.date_start,
				period_type: res.period_type,
				lead_sources: res.lead_sources,
				name: res.name,
				traffic: res.traffic,
				payment_type: res.payment_type,
				program_type: res.program_type,
				status: res.status,
				lead_fields: res.lead_fields,
				with_scoring: res.with_scoring,
				additional_lead_sources: res.additional_lead_sources
			}
			for(let item of b.additional_lead_sources){
				this.additionalSources += item;
			}
			let cities = [];
			for(let item of b.cities){
				cities.push(item.id*1);
			}
			b.cities = cities;

			let leadsources = [];
			for(let item of b.lead_sources){
				leadsources.push(item.id*1);
			}
			b.lead_sources = leadsources;

			let leadfields = [];
			for(let item of b.lead_fields){
				leadfields.push(item.id*1);
			}
			b.lead_fields = leadfields;

			let categories = [];
			for(let item of b.categories){
				categories.push(item.id*1);
			}
			b.categories = categories;

			this.confg = b;
		})

		this.app.getCities().subscribe(res => {
			this.cities = res;
		})
		this.app.getLeadFields().subscribe(res => {
			this.leadFields = res;
		})

		this.app.getLeadSource().subscribe(res => {
			this.leadSource = res;
		})

		this.app.getCategories().subscribe(res => {
			this.categories = res;
		})

	}

	changeCategory(id){
		var index = this.confg.categories.indexOf(id);
		if(index === -1){
			this.confg.categories.push(id);
		}else{
			this.confg.categories.splice(index,1);
		}
	}

	changeLeadFields(id){
		var index = this.confg.lead_fields.indexOf(id);
		if(index === -1){
			this.confg.lead_fields.push(id);
		}else{
			this.confg.lead_fields.splice(index,1);
		}
	}

	changeLeadSource(id){
		var index = this.confg.lead_sources.indexOf(id);
		if(index === -1){
			this.confg.lead_sources.push(id);
		}else{
			this.confg.lead_sources.splice(index,1);
		}
	}

	changeCity(id){
		var index = this.confg.cities.indexOf(id);
		if(index === -1){
			this.confg.cities.push(id);
		}else{
			this.confg.cities.splice(index,1);
		}
	}

	onSave(){
		this.confg.additional_lead_sources.push(this.additionalSources);
		
		this.app.put('/core/bank_aggregator/program/' + this.id + '/',this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/aggregator/list']);
			}
		}, error => {
			console.log(error)
			this.msg.addToast(error.message, 'error');
		})
	}

}