import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-aggregator-bank',
  templateUrl: './aggregator.component.html',
  styleUrls: ['./aggregator.component.css']
})
export class BankAggregatorComponent implements OnInit {

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
	}

}