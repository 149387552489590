import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class AgShowProgramComponent implements OnInit {
	id;
	program;
	leads;
	expanded = false;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
	}

	ngOnInit() {
		this.checkCountry();
		this.id = this.route.snapshot.params['id'];
		this.app.getProgram(this.id).subscribe(res => {
			this.program = res;
			this.getLeads(res.id);
		})
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	toggle(){
	     this.expanded = !this.expanded;
	}


	getLeads(id){
		this.app.get('/core/fin_institute/lead/?only_aggregator=true&program='+id).subscribe(res =>{
			this.leads = res.results;
		})
	}
}