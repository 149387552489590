import {Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from '../../_services/message.service';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.css']
})
export class RevisionComponent implements OnInit {
	
	id;
	revision;
	changingIds = [];
	leads;
	country;

	modalCSV = false;
	leadStatus = 'LEAD_ACCEPTED';

	form: FormGroup;
  	loading: boolean = false;

  	@ViewChild('fileInput') fileInput: ElementRef;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private fb: FormBuilder,
	      		private msg : MessageService) {
		this.createForm();
	}

	ngOnInit() {
		this.checkCountry();
		this.id = this.route.snapshot.params['id'];
		this.getList();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	createForm() {
		this.form = this.fb.group({
			file: [null,Validators.required],
		});
	}

	onSelectDict(event){
		this.leadStatus = event;
	}

	private prepareSave(): any {
		let input = new FormData();
		input.append('file', this.form.get('file').value);
		input.append('status', this.leadStatus);
		return input;
	}

  	onFileChange(event) {
	    if(event.target.files.length > 0) {
	      let file = event.target.files[0];
	      this.form.get('file').setValue(file);
	    }
	}

	onSubmit() {
		const formModel = this.prepareSave();
		this.loading = true;
		// In a real-world app you'd have a http request / service call here like
		// this.http.post('apiUrl', formModel)
		this.app.uploadXLS(formModel, this.id).subscribe(res => {
			this.getList();
			this.clearFile();
			this.modalCSV = false;
			this.loading = false;
		}, error => {
			if(error.message === 'Unexpected token X in JSON at position 0'){
				this.msg.addToast('В сверке добавлен сторонний лид', 'error');
			}else{
				this.msg.addToast(error.message, 'error');
			}
			this.loading = false;
		})
	}

	clearFile() {
		this.form.get('file').setValue(null);
		this.fileInput.nativeElement.value = '';
	}


	getList(){
		this.app.getSingleRevision(this.id).subscribe(res => {
			this.revision = res;
			this.getLeads(this.revision.program.id);
		})
	}

	changeLeads(id){
		var index = this.changingIds.indexOf(id);
		if(index === -1){
			this.changingIds.push(id);
		}else{
			this.changingIds.splice(index,1);
		}
	}

	changeStatus(status){
		this.app.changeStatus(status, this.changingIds).subscribe(res => {
			this.getList();
			this.changingIds = [];
		})
	}

	getLeads(id){
		this.app.get('/core/fin_institute/lead/?only_aggregator=false&program='+id + '&revision=' + this.id).subscribe(res =>{
			this.leads = res.results;
		})
	}

	doExcel() {
		let link = "/core/fin_institute/revision/" + this.id + "/export_leads/";
	    this.app.getReportExcel(link).subscribe(data => {
	        this.downloadFile(data._body);
	    });
  	}
	
	downloadFile(data) {
		let today: number = Date.now();
	    let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
	    var blobURL = window.URL.createObjectURL(blob);
	    var anchor = document.createElement("a");
	    anchor.download = "report"+today+".xls";
	    anchor.href = blobURL;
	    anchor.click();
  	}

}