import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';
import {MessageService} from '../../_services/message.service';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class FinAddComponent implements OnInit {
	
	cities;
	leadFields;
	leadSource;

	categories;

	confg = {
		categories:[],
		cities:[],
		date_end:"",
		date_start:"",
		period_type: 'EVERY_DAY',
		lead_sources:[],
		name:"",
		traffic: null,
		payment_type: "CONTRACT",
		program_type: "DEBET_CARD",
		status: "ACTIVE",
		lead_fields: []
	}

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {

		this.app.getCities().subscribe(res => {
			this.cities = res;
		})
		this.app.getLeadFields().subscribe(res => {
			this.leadFields = res;
		})

		this.app.getLeadSource().subscribe(res => {
			this.leadSource = res;
		})

		this.app.getCategories().subscribe(res => {
			this.categories = res;
		})
	}

	changeCategory(id){
		console.log(id)
		var index = this.confg.categories.indexOf(id);
		if(index === -1){
			this.confg.categories.push(id);
		}else{
			this.confg.categories.splice(index,1);
		}
	}

	changeLeadFields(id){
		var index = this.confg.lead_fields.indexOf(id);
		if(index === -1){
			this.confg.lead_fields.push(id);
		}else{
			this.confg.lead_fields.splice(index,1);
		}
		console.log('asd')
	}

	changeLeadSource(id){
		var index = this.confg.lead_sources.indexOf(id);
		if(index === -1){
			this.confg.lead_sources.push(id);
		}else{
			this.confg.lead_sources.splice(index,1);
		}
		console.log('dsa')
	}

	changeCity(id){
		var index = this.confg.cities.indexOf(id);
		if(index === -1){
			this.confg.cities.push(id);
		}else{
			this.confg.cities.splice(index,1);
		}
	}

	onSave(){
		this.app.addProgramAggr(this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/aggregator/list']);
			}
		}, error=> {
			this.msg.addToast(error.message, 'error');
		})
	}

}