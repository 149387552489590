import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';
import {MessageService} from '../../_services/message.service';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
	
	cities;
	leadFields;
	leadSource;
	showed = 0;
	categories;
	additionalSources = "";

	current = Date.now();

	confg = {
		categories:[],
		cities:[],
		date_end:"",
		date_start:"",
		period_type: 'EVERY_DAY',
		lead_sources:[],
		name:"",
		traffic: null,
		payment_type: "LEAD",
		program_type: "DEBET_CARD",
		is_active: "true",
		lead_fields: [],
		with_scoring: false,
		url: "",
		additional_lead_sources: []
	}

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {

		this.app.getCities().subscribe(res => {
			this.cities = res;
		})
		this.app.getLeadFields().subscribe(res => {
			this.leadFields = res;
		})

		this.app.getLeadSource().subscribe(res => {
			this.leadSource = res;
		})

		this.app.getCategories().subscribe(res => {
			this.categories = res;
		})
	}

	changeCategory(id){
		var index = this.confg.categories.indexOf(id);
		if(index === -1){
			this.confg.categories.push(id);
		}else{
			this.confg.categories.splice(index,1);
		}
	}

	changeLeadFields(id){
		var index = this.confg.lead_fields.indexOf(id);
		if(index === -1){
			this.confg.lead_fields.push(id);
		}else{
			this.confg.lead_fields.splice(index,1);
		}
	}

	changeLeadSource(id){
		var index = this.confg.lead_sources.indexOf(id);
		if(index === -1){
			this.confg.lead_sources.push(id);
		}else{
			this.confg.lead_sources.splice(index,1);
		}
	}

	changeCity(id){
		var index = this.confg.cities.indexOf(id);
		if(index === -1){
			this.confg.cities.push(id);
		}else{
			this.confg.cities.splice(index,1);
		}
	}

	onSave(){
		this.confg.additional_lead_sources = [];
		this.confg.additional_lead_sources.push(this.additionalSources);
		this.app.addProgram(this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/programs/list']);
			}
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	changeShowed(id){
		if(this.showed === id){
			this.showed = 0;	
		}else{
			this.showed = id;
		}
	}
}