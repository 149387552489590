import {Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from '../../../_services/message.service';
import {AppletService} from '../../../_services/applet.service';

@Component({
  selector: 'app-revisions',
  templateUrl: './revisions.component.html',
  styleUrls: ['./revisions.component.css']
})
export class GeneratorRevisionsComponent implements OnInit {
	
	list;

	modalCSV = false;
	showH = false;
	offset = 0;	
	page = 1;
	collectionSize = '';
	limit = 50;

	form: FormGroup;
  	loading: boolean = false;

  	@ViewChild('fileInput') fileInput: ElementRef;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private fb: FormBuilder,
	      		private msg : MessageService) {
		this.createForm();
	}

	ngOnInit() {
		this.getReviosion();
	}

	loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getReviosion();
		}
	}

	getReviosion(){	
		this.offset = (this.page - 1) * this.limit;
		this.app.get('/core/lead_generator/revision/?only_aggregator=false&limit=' + this.limit + '&offset=' + this.offset).subscribe(res => {
			this.list = res.results;
			this.showH = !res.results.length;
			this.collectionSize = res.count;
		}, error => {
			this.msg.addToast(error.message, 'error');
		}
		)
	}

	createForm() {
	    this.form = this.fb.group({
	      file: [null,Validators.required]
	    });
	  }


	private prepareSave(): any {
		let input = new FormData();
		input.append('file', this.form.get('file').value);
		return input;
	}

  	onFileChange(event) {
	    if(event.target.files.length > 0) {
	      let file = event.target.files[0];
	      this.form.get('file').setValue(file);
	    }
	}

	onSubmit() {
		const formModel = this.prepareSave();
		this.loading = true;
		// In a real-world app you'd have a http request / service call here like
		// this.http.post('apiUrl', formModel)
		this.app.uploadCSV(formModel).subscribe(res => {
			this.getReviosion();
			this.clearFile();
			this.modalCSV = false;
			this.loading = false;
		}, error => {
			this.msg.addToast(error.message, 'error');
			this.loading = false;
		})
	}

	clearFile() {
		this.form.get('file').setValue(null);
		this.fileInput.nativeElement.value = '';
	}

}