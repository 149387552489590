import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

  

export class AggrMainComponent implements OnInit {
	
	@ViewChild('myCanvas') canvas: ElementRef;
	lineChartColorsD;
	
	balance_dict = [];
	selectedDict;
	balanceList = [];
	aggregators;
	
	selectedType = 365;

	multi;
	totalCount;
	single = [];
	country;

	lastStats = [];
	filtering = {
		date_created_begin: null,
		date_created_end: null,
		fin_institute: '',
		program: '',
		program__payment_type:''
	}

	singleLast = [];
	programs;
	dataProgramsSum;

	today: number = Date.now();


	public doughnutChartLabels:string[] = [];
	public doughnutChartData:number[] = [];
	public doughnutChartType:string = 'doughnut';
	// public backgroundColor = [];
	public lineChartColors:Array<any> = [
		{ backgroundColor: ['#cc99ff', '#99ff99', '#ff9999','#cc99cc','#33ccff','#ffff66'] },
		{ borderColor: ['#cc99ff', '#99ff99', '#ff9999','#cc99cc','#33ccff','#ffff66'] }
	];

	public datasets = [
	    {
	      label: '',
	      data: [],
	    }
	  ];

	dataPrograms = [];
	dataProgramsLabels;




 	public chartColors: any = [{
      backgroundColor: 'rgba(72, 167, 252, 0.44)',
      borderColor: '#158cfb',
    }]

  	labels = [];
	public options = {
		elements : { line : { tension : 0 } },
	    scales: {
	      yAxes: [{
	        ticks: {
	          beginAtZero: true,
	        },
	      }],
	      xAxes: [{
			  gridLines: {
			    display: false,
			  },
			}],

	    },
	    maintainAspectRatio: false
	  };

	 optionsPie = {
	 	elements: {
	        arc: {
	            borderWidth: 0
	        }
	    },
	    maintainAspectRatio: false
	 }

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
		this.checkCountry();
		this.balanceDicts();
		this.app.get('/core/fin_institute/list').subscribe(res =>{
			this.aggregators = res;
			this.app.getProgramsAggr('all').subscribe(res =>{
				this.programs = res;
			})
		});
		this.getStats();
		let gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 200);
			gradient.addColorStop(0, 'rgba(104, 232, 255, 0.47)');
			gradient.addColorStop(1, 'rgba(104, 232, 255, 0.1)');
			this.lineChartColorsD = [
			  {
			    backgroundColor: gradient,
			    borderColor: '#119ce4',
				pointBackgroundColor: 'transparent',
				pointBorderColor: 'transparent',
				pointHoverBackgroundColor: '#fff',
    			pointHoverBorderColor: '#138bfb'
			  }
			];
	}

	public chartClicked(e:any):void {
		console.log(e);
	}

	public chartHovered(e:any):void {
		console.log(e);
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getProgramsById(id){
		this.app.get('/core/aggregator/program/?fin_institute='+id).subscribe(res => {
			this.programs = res;
		})
	}

	selectItem(type, val){
		if(val !=='all' &&  val !=='my'){
			this.filtering[type] = val;
		}else{
			this.filtering[type] = '';
		}
		if(type === 'fin_institute'){
			this.programs = [];
			if(val !=='my'){
				this.getProgramsById(val);
			}else{
				this.app.getProgramsAggr('all').subscribe(res =>{
					this.programs = res;
				})
			}
		}

		this.getStats();
	}


	balanceDicts(){
		this.app.getUserByToken().subscribe(res => {
		    this.doughnutChartLabels = [];
		    let total = 0;


		    for(let key of Object.keys(res.user.aggregator.balance_dict)){
		    	let item = res.user.aggregator.balance_dict[key];
		    	total += 1*item.balance;
		    }
			for(let key of Object.keys(res.user.aggregator.balance_dict)){
				let item = res.user.aggregator.balance_dict[key];
		     	this.balance_dict.push({'name':item.name, 'value': item.balance, id: key, 'perc' : item.percent});
		    	this.doughnutChartLabels.push(item.name + ' ' + item.percent + '%')
		    	this.doughnutChartData.push(item.balance)
		    }
		    this.selectedDict = this.balance_dict[0];
		    this.totalCount = total;
		});
		this.app.get('/core/aggregator/balance_operation/?limit=1000&only_aggregator=false').subscribe(res => {
			this.balanceList = res.results;
			if(this.balanceList.length){
		    	setTimeout(() => {
		    		this.drowGraph(this.selectedDict);
			    }, 200);
			}
		})
	}

	getStats(){

		this.filtering.date_created_end = new Date(this.today).toISOString();
		let now = new Date();
		now.setDate(now.getDate()-this.selectedType);
		this.filtering.date_created_begin = now.toISOString();

		this.singleLast = [];
		let filter = '';
	    for(let key of Object.keys(this.filtering)){
	      if(this.filtering[key]){
	        filter += '&' + key + '=' + this.filtering[key];
	      }
	    }

		this.app.get('/core/aggregator/stats/?w=1' + filter).subscribe(res => {
			this.dataPrograms = [];
		    this.dataProgramsLabels = [];
		    this.dataProgramsSum = 0;

			this.lastStats = res;
			for(let item of res.spent_balance_info){
				this.dataProgramsSum += item.sum;
			}
			for(let item of res.spent_balance_info){
				this.singleLast.push({'name':item.program__name, 'value': item.sum, "perc": item.percent.toString().substr(0, 4) })
				// console.log(item.sum)
				this.dataPrograms.push(item.sum);
				this.dataProgramsLabels.push(item.program__name)
			}
		})
		// this.lastStats = [];
	}

	selectedDate(type){
		this.selectedType = type;
		this.getStats();
	}


	drowGraph(elem){
		this.balanceList.sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime());
		this.datasets = [
		    {
		      label: elem.name,
		      data: [],
		    }
		  ];
		this.labels = [];
		let data = [];
		for(let item of this.balanceList){
			let date = new Date(item.date_created);
			let d = date.getDate() + ' ' + this.returnMonth(date.getMonth()+1);
			data.push(d);
			if(item.aggregator_balance_dict[elem.id]){
				this.datasets[0].data.push(item.aggregator_balance_dict[elem.id].balance);
			}
		}
		this.labels = data;
	}

	returnMonth(number){
		number = number *1;
		switch (number) {
			case 1:
				return 'янв';
			case 2:
				return 'фев';
			case 3:
				return 'мар';
			case 4:
				return 'апр';
			case 5:
				return 'май';
			case 6:
				return 'июн';
			case 7:
				return 'июл';
			case 8:
				return 'авг';
			case 9:
				return 'сен';
			case 10:
				return 'окт';
			case 11:
				return 'ноя';
			case 12:
				return 'дек';
		}
		
	}

	onSelectDict(val){
		this.selectedDict = val;
		this.drowGraph(this.selectedDict);
	}
}