import {Injectable} from '@angular/core';
import {Http, Headers, Response, RequestOptions, ResponseContentType} from '@angular/http';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class AppletService {
	// dev
	// API_URL = 'http://dev-back.trafficwave.kz';
	// prod
	API_URL = 'https://api.trafficwave.kz';
	stats;

	constructor(private http: Http,
              	private httpClient: HttpClient,
              	public router: Router) {
  	}

	authenticate(phone: any): Observable<any> {
	    const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
	    return this.http
	      	.post(this.API_URL+'/auth/activation/login/', {phone:phone }, 
	        	{headers: headers})
	      	.map(res => {
		        return res.json();
	      	})
	      	.catch(this.handleError);
	}

	returnLocation(){
		let url = window.location.href;
		if(url.indexOf('.kz') != -1){
			return '.kz';
		} else if(url.indexOf('.kg') != -1){
			return '.kg';
		} else if(url.indexOf('.ru') != -1){
			return '.ru';
		}else {

	    }
	}

	getStats(): Observable<any> {
	    const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/analyst/stats/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
	      		this.stats = resp.result;
		        return res.json();
	      	})
	      	.catch(this.handleError);
	}

	enterCode(id:any, code:any): Observable<any> {
	    const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
	    return this.http
	      	.post(this.API_URL+'/auth/activation/'+id+'/activate/', {code:code }, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();

	      		window.localStorage.setItem('auth_key', resp.result.token);
		        window.localStorage.setItem('user', JSON.stringify(resp.result.user));

		        return resp;
	      	})
	      	.catch(this.handleError);
	}

	enterCodeSMS(id:any, code:any): Observable<any> {
	    const headers = new Headers();
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/auth/activation/'+id+'/activate/', {code:code }, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp;
	      	})
	      	.catch(this.handleError);
	}

	checkAuth() {
	    return window.localStorage.getItem('auth_key') && window.localStorage.getItem('user');
	}

	getPrograms():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/program/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getProgramsFin(id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
    	let settings = '';
    	if(id !== '-1' && id !== 'all'){
    		settings = '?aggregator=' + id;
    	}
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/program/' + settings, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getProgramsAggr(id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
    	let settings = '';
    	if(id !== '-1' && id !== 'all'){
    		settings = '?aggregator=' + id;
    	}
	    return this.http
	      	.get(this.API_URL+'/core/aggregator/program/' + settings, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getReportExcel(settings): Observable<any> {
	   	const headers = new Headers();

	    headers.append('Content-Type', 'application/json');
	    // headers.append('Accept', 'application/vnd.ms-excel');
	    headers.append('Authorization', 'JWT ' + this.getToken());

	    let options = new RequestOptions({headers: headers, responseType: ResponseContentType.Blob});

	    return this.http
	      .get(this.API_URL + settings,options)
	      .map((res: Response) => {
	        return res;
	      })
	      .catch(this.handleError);
  	}


	getCities():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/city/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getCategories():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/category/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getRevisionList():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/revision/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	uploadCSV(file):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/revision/import_csv/', file,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	uploadFile(url,file):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+url, file,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	uploadXLS(file, id):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/revision/' + id + '/update_leads_xls/', 
	      		file,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getSingleRevision(id):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/revision/' + id,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	changeStatus(status,leads ):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/lead/update_status/',  {leads : leads , status : status},
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	changeStatusBank(status,leads ):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/bank_aggregator/lead/update_status/',  {leads : leads , status : status},
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}


	changeStatusAnalyst(status,leads ):Observable<any>{
		const headers = new Headers();
    	headers.append('mimeType', 'multipart/form-data');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/analyst/lead/update_status/',  {leads : leads , status : status},
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getLeadFields():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/lead_field/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getAnalystList(settings, limit, offset):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/analyst/balance_request/' + settings + '&limit=' + limit + '&offset=' + offset, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}


	getList(url, settings, limit, offset):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/analyst/'+ url +'/' + settings + '&limit=' + limit +  '&offset=' + offset, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	changeListItemStatus(url ,settings, id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/analyst/' + url +'/' + id + '/' + settings, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	updateProgramsAnalyst(url ,settings, id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.put(this.API_URL+'/core/analyst/' + url + '/' + id + '/', settings,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getProgramsById(settings):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/program/?' + settings, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}


	updateListItemStatus(url, id, amount):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.put(this.API_URL+'/core/analyst/' + url +'/' + id + '/', {amount :amount}, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}
	
	changeListStatus(id,settings):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/analyst/balance_request/' + id + '/' + settings, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	updateListStatus(id, amount):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.put(this.API_URL+'/core/analyst/balance_request/' + id + '/', {amount :amount}, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getAggregatorList():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/aggregator/list/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getFinInstituteList():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/list/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getLeadSource():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/lead_source/', 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	addProgram(confg):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/program/', confg, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	addProgramAggr(confg):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/program/?only_aggregator=true', confg, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getProgram(id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/program/' + id, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getProgramAggr(id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/aggregator/program/' + id, 
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	putProgram(id, confg):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.put(this.API_URL+'/core/fin_institute/program/' + id + '/', confg,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	putProgramAggr(id, confg):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.put(this.API_URL+'/core/aggregator/program/' + id + '/', confg,
	        	{headers: headers})
	      	.map(res => {
	      		let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	deleteProgram(id):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.delete(this.API_URL+'/core/fin_institute/program/'+ id, 
	        	{headers: headers})
	      	.map(res => {
		        return true;
	      	})
	      	.catch(this.handleError);
	}

	delete(link):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.delete(this.API_URL+link, 
	        	{headers: headers})
	      	.map(res => {
		        return true;
	      	})
	      	.catch(this.handleError);
	}

	balanceRequest(amount):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/balance_request/', {amount : amount}, 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getBalanceAllocationList():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/balance_allocation/', 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	createAllocation(program , amount):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/balance_allocation/', {amount : amount, program : program }, 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}


	createAllocationAggr(aggr,program , amount):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+'/core/fin_institute/balance_allocation/', {aggregator: aggr, amount : amount, program : program }, 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	getDebitList():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/balance_operation/', 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	get(link):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+link, 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	post(link, option):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.post(this.API_URL+link, option, 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	put(link, option):Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.put(this.API_URL+link, option, 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}


	GetBalanceList():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/balance_request/?only_aggregator=false&limit=5', 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}

	GetBalanceListAggr():Observable<any>{
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/core/fin_institute/balance_request/?only_aggregator=true', 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}
	

	getUser(){
		const parsedUser = JSON.parse(window.localStorage.getItem('user'));
	    if (parsedUser) {
	      return parsedUser;
	    }
	    return null;
	}

	getUserByToken(){
		const headers = new Headers();
    	headers.append('Content-Type', 'application/json');
    	headers.append('Authorization', 'JWT ' + this.getToken());
	    return this.http
	      	.get(this.API_URL+'/auth/users/get/', 
	        	{headers: headers})
	      	.map(res => {
		        let resp = res.json();
		        return resp.result;
	      	})
	      	.catch(this.handleError);
	}



	logout() {
	    window.localStorage.removeItem('auth_key');
	    window.localStorage.removeItem('user');
	    this.router.navigate(['/']);
  	}


	getToken() {
	    if (window.localStorage.getItem('auth_key')) {
	      return window.localStorage.getItem('auth_key');
	    }
	    return null;
  	}




	private handleError(error: any): Promise<any> {
		let msg = JSON.parse(error._body);
		if( msg.message === 'Signature has expired.'){
			window.localStorage.removeItem('auth_key');
		    window.localStorage.removeItem('user');
		    location.pathname = '/';
		    return Promise.reject(JSON.parse(error._body));
		}else{
	    	return Promise.reject(JSON.parse(error._body));
		}
  	}

}
