import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AppletService } from '../../_services/applet.service';


@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css']
})



export class GeneratorMainComponent implements OnInit {

	selectedType = 365;
	filtering = {
		date_created_begin: null,
		date_created_end: null,
		bank_aggregator: '',
		program: '',
		program__payment_type: ''
	}
	stats;
	doughnutMSB = [];
	doughnutMSBLabels;
	singleMSB = [];
	programs;
	datasets = [];
	labels = [];
	public lineChartColorsForMSB: Array<any> = [
		{ backgroundColor: ['#42c689', '#ff9999', '#cc99cc', '#33ccff', '#ffff66'] },
		{ borderColor: ['#99ff99', '#ff9999', '#cc99cc', '#33ccff', '#ffff66'] }
	];
	public options = {
		responsive: true,
		elements: { line: { tension: 0 } },
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				},
			}],
			xAxes: [{
				gridLines: {
					display: false
				},
			}],

		},
		maintainAspectRatio: false
	};
	lineChartColorsD = [
		{
			backgroundColor: 'gradient',
			borderColor: '#119ce4',
			pointBackgroundColor: 'transparent',
			pointBorderColor: 'transparent',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: '#138bfb'
		}
	];
	constructor(private route: ActivatedRoute,
		public app: AppletService,
		public router: Router) {
	}

	ngOnInit() {
		this.getStats();
		this.app.get('/core/lead_generator/program/').subscribe(res => {
			this.programs = res.results;
		})
	}

	getStats(){
		let inside = new Date().setHours(29,59,59,999);
		this.filtering.date_created_end = new Date(inside).toISOString();
		let now = new Date();
		now.setDate(now.getDate() - this.selectedType);
		this.filtering.date_created_begin = now.toISOString();
		let filter = '';
		for (let key of Object.keys(this.filtering)) {
			if (this.filtering[key]) {
				if(filter){
					filter += '&';
				}else{
					filter += '?';
				}
				filter += key + '=' + this.filtering[key];
			}
		}
		this.app.get('/core/lead_generator/stats/' + filter).subscribe(res => {
			this.stats = res;
			this.doughnutMSB = [];
			this.doughnutMSBLabels = [];
			this.datasets = [
				{
					label: null,
					data: [],
				}
			];
			this.labels = [];
			for (let item of res.info) {
				this.singleMSB.push({ 'name': 'План по привлечению', 'value': item.program_leads_count }, { 'name': 'Привлечено', 'value': item.accepted_leads_count }, { 'name': 'Осталось', 'value': item.left_leads_count })
				this.doughnutMSB.push(item.accepted_leads_count, item.left_leads_count);
				this.doughnutMSBLabels.push('Привлечено', 'Осталось');
			}
			let data = [];
			let bs = [];
			for(let item of res.leads_stats){
				data.push(item.date);
				this.datasets[0].data.push(item.accepted_leads_count)
			}
			this.labels = data;
		})
	}

	selectItem(type, val) {
		if (val !== 'all' && val !== 'my') {
			this.filtering[type] = val;
		} else {
			this.filtering[type] = '';
		}
		this.getStats();
	}

	selectedDate(type) {
		this.selectedType = type;
		this.getStats();
	}

}