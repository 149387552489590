import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class FinListComponent implements OnInit {
	
	list;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
		this.checkCountry();
		this.getProgram();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getProgram(){
		// this.app.getPrograms().subscribe(res => {
		// 	this.list = res;
		// })
		this.app.get('/core/fin_institute/program/?only_aggregator=true').subscribe(res=>{
			this.list = res.results;
		})
	}

	deleteProgram(id){
		this.app.deleteProgram(id).subscribe(res => {
			this.getProgram();
		})
	}

}