import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AppletService } from '../_services/applet.service';


@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css']
})



export class MainComponent implements OnInit {

	@ViewChild('myCanvas') canvas: ElementRef;
	lineChartColorsD;

	balance_dict = [];
	accepted_leads_count = [];
	balancemsb_dict = [];
	selectedDict;
	selectedLead;
	balanceList = [];
	aggregators;
	country;
	msbStats;

	selectedType = 365;

	multi;
	totalCount;
	single = [];

	lastStats = [];
	filtering = {
		date_created_begin: null,
		date_created_end: null,
		aggregator: '',
		program: '',
		program__payment_type: ''
	}

	showSecond = false;

	singleLast = [];
	singleMSB = [];
	programs;
	dataProgramsSum;
	mychart;

	today: number = Date.now();


	public doughnutChartLabels: string[] = [];
	public doughnutChartData: number[] = [];
	public doughnutChartType: string = 'doughnut';
	// public backgroundColor = [];
	public lineChartColors: Array<any> = [
		{ backgroundColor: ['#cc99ff', '#99ff99', '#ff9999', '#cc99cc', '#33ccff', '#ffff66'] },
		{ borderColor: ['#cc99ff', '#99ff99', '#ff9999', '#cc99cc', '#33ccff', '#ffff66'] }
	];

	public lineChartColorsForMSB: Array<any> = [
		{ backgroundColor: ['#42c689', '#ff9999', '#cc99cc', '#33ccff', '#ffff66'] },
		{ borderColor: ['#99ff99', '#ff9999', '#cc99cc', '#33ccff', '#ffff66'] }
	];

	public datasets = [
		{
			label: '',
			data: [],
		}
	];

	public datasetsSecond = [
		{
			label: '',
			data: [],
		}
	];

	dataPrograms = [];
	dataProgramsLabels;

	doughnutMSB = [];
	doughnutMSBLabels;


	public chartColors: any = [{
		backgroundColor: 'rgba(72, 167, 252, 0.44)',
		borderColor: '#158cfb',
	}]

	labels = [];
	labelsSecond = [];
	public options = {
		responsive: true,
		elements: { line: { tension: 0 } },
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				},
			}],
			xAxes: [{
				gridLines: {
					display: false
				},
			}],

		},
		maintainAspectRatio: false
	};

	optionsPie = {
		elements: {
			arc: {
				borderWidth: 0
			}
		},
		maintainAspectRatio: false
	}

	constructor(private route: ActivatedRoute,
		public app: AppletService,
		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
		this.checkCountry();
		this.balanceDicts();
		this.app.get('/core/aggregator/list').subscribe(res => {
			this.aggregators = res;
			this.app.getProgramsFin('all').subscribe(res => {
				this.programs = res.results;
			})
		});
		this.getStats();
		this.refreshChart();
		let gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 200);
		gradient.addColorStop(0, 'rgba(104, 232, 255, 0.47)');
		gradient.addColorStop(1, 'rgba(104, 232, 255, 0.1)');
		this.lineChartColorsD = [
			{
				backgroundColor: gradient,
				borderColor: '#119ce4',
				pointBackgroundColor: 'transparent',
				pointBorderColor: 'transparent',
				pointHoverBackgroundColor: '#fff',
				pointHoverBorderColor: '#138bfb'
			}
		];
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user.country === 'KZ') {
			this.country = 'тг';
		} else if (user.country === 'KG') {
			this.country = 'сом';
		} else if (user.country === 'RU') {
			this.country = 'рублей';
		}
	}

	public chartClicked(e: any): void {
		console.log(e);
	}

	public chartHovered(e: any): void {
		console.log(e);
	}

	selectItem(type, val) {
		if (val !== 'all' && val !== 'my') {
			this.filtering[type] = val;
		} else {
			this.filtering[type] = '';
		}
		if (type === 'aggregator') {
			this.programs = [];
			if (val !== 'my') {
				this.app.getProgramsFin(val).subscribe(res => {
					this.programs = res.results;
				})
			} else {
				this.app.get('/core/fin_institute/program/?only_aggregator=false').subscribe(res => {
					this.programs = res.results;
				})
			}
		}
		this.refreshChart();
		this.getStats();
	}

	balanceDicts() {
		this.app.getUserByToken().subscribe(res => {
			this.doughnutChartLabels = [];
			let total = 0;

			for (let key of Object.keys(res.user.fin_institute.balance_dict)) {
				let item = res.user.fin_institute.balance_dict[key];
				total += 1 * item.balance;
			}
			for (let key of Object.keys(res.user.fin_institute.balance_dict)) {
				let item = res.user.fin_institute.balance_dict[key];
				this.balance_dict.push({ 'name': item.name, 'value': item.balance, id: key, 'perc': item.percent });
				this.doughnutChartLabels.push(item.name + ' ' + item.percent + '%')
				this.doughnutChartData.push(item.balance)
			}
			this.selectedDict = this.balance_dict[0];
			this.totalCount = total;
			if (Object.keys(res.user.fin_institute.balance_dict).length < 2) {
				this.showSecond = true;
			}
			this.getS();
		});
	}

	getS(){
		console.log('s')
		this.app.get('/core/fin_institute/balance_operation/?limit=500&only_aggregator=false').subscribe(res => {
			this.balanceList = res.results;
			if (this.balanceList.length) {
				this.drowGraph(this.selectedDict);
			}
		})
	}

	LeadDicts() {
		for (let key of Object.keys(this.msbStats.leads_stats)) {
			let item = this.msbStats.leads_stats[key];
			this.accepted_leads_count.push({ 'name': 'Количество лидов', 'value': item.accepted_leads_count, id: key });
			this.doughnutChartLabels.push('Количество лидов');
			this.doughnutChartData.push(item.accepted_leads_count);
		}
		for (let key of Object.keys(this.accepted_leads_count)) {
			this.selectedLead = this.accepted_leads_count[key];
		}
		if (Object.keys(this.msbStats).length > 0) {
			setTimeout(() => {
				this.drowGraphSecond(this.selectedLead);
			}, 200);
		}
	}

	getStats() {
		let inside = new Date().setHours(29,59,59,999);
		this.filtering.date_created_end = new Date(inside).toISOString();
		let now = new Date();
		now.setDate(now.getDate() - this.selectedType);
		this.filtering.date_created_begin = now.toISOString();

		this.singleLast = [];
		this.singleMSB = [];
		let filter = '';
		for (let key of Object.keys(this.filtering)) {
			if (this.filtering[key]) {
				filter += '&' + key + '=' + this.filtering[key];
			}
		}

		this.app.get('/core/fin_institute/stats/?date_created_begin=' + this.filtering.date_created_begin + '&date_created_end=' + this.filtering.date_created_end + filter).subscribe(res => {
			this.dataPrograms = [];
			this.dataProgramsLabels = [];
			this.dataProgramsSum = 0;
			this.lastStats = res;

			if (this.app.getUser().fin_institute.is_smb === false) {
				for (let item of res.spent_balance_info) {
					this.dataProgramsSum += item.sum;
				}
				for (let item of res.spent_balance_info) {
					this.singleLast.push({ 'name': item.program__name, 'value': item.sum, "perc": item.percent.toString().substr(0, 4) })
					// console.log(item.sum)
					this.dataPrograms.push(item.sum);
					this.dataProgramsLabels.push(item.program__name)
				}
			}

			if (this.app.getUser().fin_institute.is_smb === true) {
				this.doughnutMSB = [];
				this.doughnutMSBLabels = [];
				this.msbStats = res;
				for (let item of res.info) {
					this.singleMSB.push({ 'name': 'План по привлечению', 'value': item.program_leads_count }, { 'name': 'Привлечено', 'value': item.accepted_leads_count }, { 'name': 'Осталось', 'value': item.left_leads_count })
					this.doughnutMSB.push(item.accepted_leads_count, item.left_leads_count);
					this.doughnutMSBLabels.push('Привлечено', 'Осталось');
				}
				this.LeadDicts();
			}
		})
	}

	selectedDate(type) {
		this.selectedType = type;
		this.getStats();
	}

	drowGraph(elem) {
		console.log('s')
		this.balanceList.sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime());
		this.datasets = [
			{
				label: elem.name,
				data: [],
			}
		];
		this.labels = [];
		console.log(this.datasets)
		let data = [];
		for (let item of this.balanceList) {
			let date = new Date(item.date_created);
			let d = date.getDate() + ' ' + this.returnMonth(date.getMonth() + 1);
			data.push(d);
			if (item.fin_institute_balance_dict[elem.id]) {
				this.datasets[0].data.push(item.fin_institute_balance_dict[elem.id].balance);
			}
		}
		this.labels = data;
		console.log(this.datasets)
	}

	drowGraphSecond(elem) {
		this.msbStats.leads_stats.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
		this.datasetsSecond = [
			{
				label: elem.name,
				data: [],
			}
		];
		this.labelsSecond = [];
		let dataSecond = [];
		for (let item of this.msbStats.leads_stats) {
			let date = new Date(item.date);
			let d = date.getDate() + ' ' + this.returnMonth(date.getMonth() + 1);
			dataSecond.push(d);
			this.datasetsSecond[0].data.push(item.accepted_leads_count);
		}
		this.labelsSecond = dataSecond;
	}

	returnMonth(number) {
		number = number * 1;
		switch (number) {
			case 1:
				return 'янв';
			case 2:
				return 'фев';
			case 3:
				return 'мар';
			case 4:
				return 'апр';
			case 5:
				return 'май';
			case 6:
				return 'июн';
			case 7:
				return 'июл';
			case 8:
				return 'авг';
			case 9:
				return 'сен';
			case 10:
				return 'окт';
			case 11:
				return 'ноя';
			case 12:
				return 'дек';
		}

	}

	onSelectDict(val) {
		this.selectedDict = val;
		this.drowGraph(this.selectedDict);
	}

	onSelectLead(val) {
		this.selectedLead = val;
		this.drowGraphSecond(this.selectedLead);
	}
	
	refreshChart(){
		this.mychart = false;
		setTimeout(()=>{
			this.mychart = true;
		}, 500);
	}
}