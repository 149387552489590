import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class BankEditCampaignComponent implements OnInit {
	
	showed = 0;
	additionalSources = "";
	categories;
	leadSource;
	leadFields;

	current = Date.now();
	leadList;
	confg;
	id;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.id = this.route.snapshot.params['id'];
		this.app.get('/core/bank_aggregator/campaign/' + this.id).subscribe(res => {
			let b = {
				name: res.name,
				categories: res.categories,
				lead_fields: res.lead_fields,
				lead_sources: res.lead_sources,
			}
			
			let leadsources = [];
			for(let item of b.lead_sources){
				leadsources.push(item.id*1);
			}
			b.lead_sources = leadsources;

			let leadfields = [];
			for(let item of b.lead_fields){
				leadfields.push(item.id*1);
			}
			b.lead_fields = leadfields;

			let categories = [];
			for(let item of b.categories){
				categories.push(item.id*1);
			}
			b.categories = categories;

			console.log(b)
			this.confg = b;
		})

		this.app.getLeadFields().subscribe(res => {
			this.leadFields = res;
		})

		this.app.getLeadSource().subscribe(res => {
			this.leadSource = res;
		})

		this.app.getCategories().subscribe(res => {
			this.categories = res;
		})
	}


	onSave(){
		this.app.put('/core/bank_aggregator/campaign/' + this.id + '/', this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/bank/programs/campaign']);
			}
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
		
	}

	changeCategory(id){
		var index = this.confg.categories.indexOf(id);
		if(index === -1){
			this.confg.categories.push(id);
		}else{
			this.confg.categories.splice(index,1);
		}
	}

	changeLeadSource(id){
		var index = this.confg.lead_sources.indexOf(id);
		if(index === -1){
			this.confg.lead_sources.push(id);
		}else{
			this.confg.lead_sources.splice(index,1);
		}
	}

	changeLeadFields(id){
		var index = this.confg.lead_fields.indexOf(id);
		if(index === -1){
			this.confg.lead_fields.push(id);
		}else{
			this.confg.lead_fields.splice(index,1);
		}
	}

	changeShowed(id){
		if(this.showed === id){
			this.showed = 0;	
		}else{
			this.showed = id;
		}
	}
}