import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// 
import {CurrencyPipe } from '@angular/common';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppletService } from './_services/applet.service';
import { HttpModule } from '@angular/http';

import { LoginComponent  } from './login/login.component';
import { NavComponent  } from './nav/nav.component';
import { MainComponent  } from './main/main.component';
import { ProgramsComponent  } from './programs/programs.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { AddComponent  } from './programs/add/add.component';
import { AnalystComponent  } from './analyst/analyst.component';
import { AggregatorComponent  } from './analyst/balance_request/aggregator/aggregator.component';
import { FinAggregatorComponent  } from './aggregator/aggregator.component';
import {ShowAnalystProgramComponent} from './analyst/programs/show/show.component';
import {LeadsComponent} from './leads/leads.component'



import { FinInstituteComponent  } from './analyst/balance_request/fin_institute/fin_institute.component';
import { BalanceRequestComponent  } from './analyst/balance_request/balance_request.component';
import { BankRequestComponent  } from './analyst/balance_request/bank/fin_institute.component';
import { RefundRequestComponent  } from './analyst/refund/refund.component';
import { RefundAggregatorComponent  } from './analyst/refund/aggregator/refundaggregator.component';
import { RefundFinInstituteComponent  } from './analyst/refund/fin_institute/refund_fin_institute.component';

import { BalanceOperationRequestComponent  } from './analyst/balance_operation/balance_operation.component';
import { BalanceOperationAggregatorComponent  } from './analyst/balance_operation/aggregator/balanceoperationaggregator.component';
import { BalanceOperationFinInstituteComponent  } from './analyst/balance_operation/fin_institute/balance_operation_fin_institute.component';

import {AnalystProgramsRequestComponent} from './analyst/programs/analystPrograms.component';
import {ProgramsAggregatorComponent} from './analyst/programs/aggregator/programsggregator.component';
import {ProgramsFinInstituteComponent} from './analyst/programs/fin_institute/programs_fin_institute.component';

import {RevisionAnalyticsComponent} from './analyst/revision/revisionAnalytics.component';
import {RevisionAggregatorComponent} from './analyst/revision/aggregator/revisionaggregator.component';
import {RevisionFinInstituteComponent} from './analyst/revision/fin_institute/revision_fin_institute.component';

import {ToastyModule} from 'ng2-toasty';

import {MessageService} from './_services/message.service';
import {AnalystRevisionComponent} from './analyst/revision/revision/revision.component';

// import { MyDatePickerModule } from 'angular4-datepicker';

import {ReportsComponent} from './reports/reports.component';
import {ReportsProgramsComponent} from './reports/reports_programs/reports_programs.component';
import {ReportsAggregatorsComponent} from './reports/reports_aggregators/reports_aggregators.component';
import {ReportsWritingHistoryComponent} from './reports/reports_programs/writing_history/writing_history.component';
import {ReportsWritingReplenishmentComponent} from './reports/reports_programs/writing_replenishment/writing_replenishment.component';
import {DistributionOfFundsComponent} from './reports/reports_programs/distribution_of_funds/distribution_of_funds.component';
import {RevisionHistoryComponent} from './reports/reports_programs/revision_history/revision_history.component';
import {ReportRevisionComponent} from './reports/reports_programs/revision/revision.component';

import {AggrReportsWritingHistoryComponent} from './reports/reports_aggregators/writing_history/writing_history.component';
import {AggrReportsWritingReplenishmentComponent} from './reports/reports_aggregators/writing_replenishment/writing_replenishment.component';
import {AggrDistributionOfFundsComponent} from './reports/reports_aggregators/distribution_of_funds/distribution_of_funds.component';
import {AggrRevisionHistoryComponent} from './reports/reports_aggregators/revision_history/revision_history.component';
import {AggrReportRevisionComponent} from './reports/reports_aggregators/revision/revision.component';

import { EditComponent  } from './programs/edit/edit.component';
import { ShowProgramComponent  } from './programs/show/show.component';
import { ListComponent  } from './programs/list/list.component';
import { RevisionsComponent  } from './programs/revisions/revisions.component';
import { RevisionComponent  } from './programs/revision/revision.component';
import { AccountComponent  } from './programs/account/account.component';
import { AllocationComponent  } from './programs/allocation/allocation.component';
import { RefundComponent  } from './programs/refund/refund.component';


import { FinAllocationComponent } from './aggregator/allocation/allocation.component';
import { FinAccountComponent  } from './aggregator/account/account.component';
import { FinListComponent  } from './aggregator/list/list.component';
import { FinEditComponent  } from './aggregator/edit/edit.component';
import { AgShowProgramComponent  } from './aggregator/show/show.component';
import { FinAddComponent  } from './aggregator/add/add.component';
import { FinRevisionComponent  } from './aggregator/revision/revision.component';
import { FinRevisionsComponent  } from './aggregator/revisions/revisions.component';

// import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { TextMaskModule } from 'angular2-text-mask';
import {AuthGuard} from './_guards/auth.guard';
import {LOCALE_ID } from '@angular/core';

// import Bootstrap for Angular
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// Aggregators
import {AggregatorsComponent} from './aggregators/aggregators.component';
import {AggrMainComponent} from './aggregators/main/main.component';
import {FinanceComponent} from './aggregators/finance/finance.component';
import {AggrRefundComponent} from './aggregators/finance/refund/refund.component';
import {AggrShowProgramComponent} from './aggregators/finance/show/show.component';

import {FinanceAccountComponent} from './aggregators/finance/account/account.component';
import {AggregatorsAllocationComponent} from './aggregators/finance/allocation/allocation.component';
import {FinanceProgramsListComponent} from './aggregators/finance/list/list.component';
import {AggregatorAddProgramComponent} from './aggregators/finance/add/add.component';
import {AggrEditComponent} from './aggregators/finance/edit/edit.component';
import {AggrReportsAggregatorsComponent} from './aggregators/reports/reports_aggregators.component';
import {AggregatorReportsWritingReplenishmentComponent} from './aggregators/reports/writing_replenishment/writing_replenishment.component'
import {AggregatorReportsWritingHistoryComponent} from './aggregators/reports/writing_history/writing_history.component';
import {AggregatorDistributionOfFundsComponent} from './aggregators/reports/distribution_of_funds/distribution_of_funds.component';

import {Money} from './_pipes/money.pipe';
import {SettingsComponent} from './settings/settings.component';
import {SettingsCommonComponent} from './settings/common/common.component';
import {FinUsersComponent} from './settings/users/users.component';
import {FinUsersCreateComponent} from './settings/users/create/create.component';
import {FinUsersListComponent} from './settings/users/list/list.component';
import {FinUsersEditComponent} from './settings/users/edit/edit.component';



import {BankComponent} from './bank/bank.component';
import {BankSettingsComponent} from './bank/settings/settings.component';
import {CommonComponent} from './bank/settings/common/common.component';
import {BankUsersComponent} from './bank/settings/users/users.component';
import {BankUsersListComponent} from './bank/settings/users/list/list.component';
import {BankUsersCreateComponent} from './bank/settings/users/create/create.component';
import {BankUsersEditComponent} from './bank/settings/users/edit/edit.component';
import {BankMainComponent} from './bank/main/main.component';
import {BankLeadsComponent} from './bank/leads/leads.component';
import {BankProgramsComponent} from './bank/programs/programs.component';
import {BankListComponent} from './bank/programs/list/list.component';
import {BankListCompaignComponent} from './bank/programs/listcampaign/list.component';
import {BankAddComponent} from './bank/programs/add/add.component';
import {BankAddCampaignComponent} from './bank/programs/addcampaign/add.component';
import {BankEditComponent} from './bank/programs/edit/edit.component';
import {BankEditCampaignComponent} from './bank/programs/editcampaign/edit.component';
import {BankShowProgramComponent} from './bank/programs/show/show.component';
import {BankAccountComponent} from './bank/programs/account/account.component';
import {BankAllocationComponent} from './bank/programs/allocation/allocation.component';
import {BankRevisionsComponent} from './bank/programs/revisions/revisions.component';
import {BankRevisionComponent} from './bank/programs/revision/revision.component';
import {BankRefundComponent} from './bank/programs/refund/refund.component';
import {BankVisionsComponent} from './bank/visions/visions.component';
import {BankVisionsNewComponent} from './bank/visions/new/new.component';
import {BankVisionsAcceptedComponent} from './bank/visions/accpeted/new.component';
import {BankVisionsRejectedComponent} from './bank/visions/rejected/new.component';
import {BankVisionsShowComponent} from './bank/visions/show/show.component';

import {BankAggregatorComponent} from './bank/aggregator/aggregator.component';
import {BankAggrAccountComponent} from './bank/aggregator/account/account.component';
import {BankAggrListComponent} from './bank/aggregator/list/list.component';
import {BankAggrAllocationComponent} from './bank/aggregator/allocation/allocation.component';
import {BankAgShowProgramComponent} from './bank/aggregator/show/show.component';
import {BankAggrEditComponent} from './bank/aggregator/edit/edit.component';
import {BankAgrRevisionComponent} from './bank/aggregator/revision/revision.component';
import {BankAggrRevisionsComponent} from './bank/aggregator/revisions/revisions.component';

import {GeneratorComponent} from './generator/generator.component';
import {GeneratorMainComponent} from './generator/main/main.component';
import {GeneratorLeadsComponent} from './generator/leads/leads.component';
import {GeneratorProgramsComponent} from './generator/programs/programs.component';
import {GeneratorListComponent} from './generator/programs/list/list.component';
import {GeneratorRevisionsComponent} from './generator/programs/revisions/revisions.component';
import {GeneratorShowProgramComponent} from './generator/programs/show/show.component';
import {GeneratorRevisionComponent} from './generator/programs/revision/revision.component';
import {GeneratorVisionsComponent} from './generator/visions/visions.component';
import {GeneratorVisionsNewComponent} from './generator/visions/new/new.component';

import {GeneratorVisionsRejectedComponent} from './generator/visions/rejected/new.component';
import {GeneratorVisionsAcceptedComponent} from './generator/visions/accpeted/new.component';
import {GeneratorVisionsShowComponent} from './generator/visions/show/show.component';
import {GeneratorVisionsCreateComponent} from './generator/visions/create/create.component';

const appRoutes: Routes = [
	{path: '', component: LoginComponent},
  	{path: 'login', component: LoginComponent},
  	{path: 'main', component: MainComponent, canActivate: [AuthGuard]},
    {path: 'leads', component: LeadsComponent, canActivate: [AuthGuard]},
    {path: 'generator', component: GeneratorComponent, canActivate: [AuthGuard],
      children: [
          {path: '', redirectTo: 'leads', pathMatch: 'full'},
          {path: 'main', component:GeneratorMainComponent},
          {path: 'leads', component:GeneratorLeadsComponent},
          {path: 'visions', component: GeneratorVisionsComponent, canActivate: [AuthGuard],
            children: [
              {path: '', redirectTo: 'new', pathMatch: 'full'},
              {path: 'new', component: GeneratorVisionsNewComponent},
              {path: 'accepted', component: GeneratorVisionsAcceptedComponent},
              {path: 'rejected', component: GeneratorVisionsRejectedComponent},
              {path: 'show/:id', component: GeneratorVisionsShowComponent},
              {path: 'create', component: GeneratorVisionsCreateComponent},
            ]
          },
          {path: 'programs', component: GeneratorProgramsComponent, canActivate: [AuthGuard],
            children: [
              {path: '', redirectTo: 'list', pathMatch: 'full'},
              {path: 'list', component: GeneratorListComponent},
              {path: 'show/:id', component: GeneratorShowProgramComponent},
              {path: 'revisions', component: GeneratorRevisionsComponent},
              {path: 'revision/:id', component: GeneratorRevisionComponent},
            ]
          },
      ]
    },
    {path: 'bank', component: BankComponent, canActivate: [AuthGuard],
      children: [
          {path: '', redirectTo: 'main', pathMatch: 'full'},
          {path: 'main', component:BankMainComponent},
          {path: 'leads', component:BankLeadsComponent},
          {path: 'settings', component: BankSettingsComponent,
            children: [
              {path: '', redirectTo: 'common', pathMatch: 'full'},
              {path: 'common', component: CommonComponent},
              {path: 'users', component: BankUsersComponent,
                children: [
                  {path: '', redirectTo: 'list', pathMatch: 'full'},
                  {path: 'list', component: BankUsersListComponent},
                  {path: 'create', component: BankUsersCreateComponent},
                  {path: 'edit/:id', component: BankUsersEditComponent},
                ]
              },
            ]
          },
          {path: 'visions', component: BankVisionsComponent, canActivate: [AuthGuard],
            children: [
              {path: '', redirectTo: 'new', pathMatch: 'full'},
              {path: 'new', component: BankVisionsNewComponent},
              {path: 'accepted', component: BankVisionsAcceptedComponent},
              {path: 'rejected', component: BankVisionsRejectedComponent},
              {path: 'show/:id', component: BankVisionsShowComponent},
            ]
          },
          {path: 'aggregator', component: BankAggregatorComponent, canActivate: [AuthGuard],
            children: [
              {path: '', redirectTo: 'account', pathMatch: 'full'},
              {path: 'account', component: BankAggrAccountComponent},
              {path: 'list', component: BankAggrListComponent},
              {path: 'allocation', component: BankAggrAllocationComponent},
              {path: 'show/:id', component: BankAgShowProgramComponent},
              {path: 'edit/:id', component: BankAggrEditComponent},
              {path: 'revisions', component: FinRevisionsComponent},
              {path: 'revision/:id', component: FinRevisionComponent},
            ]
          },
          {path: 'programs', component: BankProgramsComponent, canActivate: [AuthGuard],
            children: [
              {path: '', redirectTo: 'account', pathMatch: 'full'},
              {path: 'list', component: BankListComponent},
              {path: 'campaign', component: BankListCompaignComponent},
              {path: 'addc', component: BankAddCampaignComponent},
              {path: 'editc/:id', component: BankEditCampaignComponent},
              {path: 'add', component: BankAddComponent},
              {path: 'edit/:id', component: BankEditComponent},
              {path: 'show/:id', component: BankShowProgramComponent},
              {path: 'account', component: BankAccountComponent},
              {path: 'allocation', component: BankAllocationComponent},
              {path: 'revisions', component: BankRevisionsComponent},
              {path: 'refund', component: BankRefundComponent},
              {path: 'revision/:id', component: BankRevisionComponent},
            ]
          },
      ]
    },
    {path: 'aggregators', component: AggregatorsComponent, canActivate: [AuthGuard],
      children: [
          {path: '', redirectTo: 'main', pathMatch: 'full'},
          {path: 'main', component:AggrMainComponent},
          {path: 'finance', component:FinanceComponent,
            children: [
              {path: '', redirectTo: 'account', pathMatch: 'full'},
              {path: 'account', component:FinanceAccountComponent},
              {path: 'allocation', component:AggregatorsAllocationComponent},
              {path: 'list', component:FinanceProgramsListComponent},
              {path: 'add', component: AggregatorAddProgramComponent},
              {path: 'edit/:id', component: AggrEditComponent},
              {path: 'refunds', component: AggrRefundComponent},
              {path: 'show/:id', component: AggrShowProgramComponent}
            ]
          },
          {path: 'reports', component: AggrReportsAggregatorsComponent, canActivate: [AuthGuard],
            children: [
              {path : '', redirectTo: 'writing-history', pathMatch: 'full'},
              {path: 'writing-history', component: AggregatorReportsWritingHistoryComponent},
              {path: 'writing-replenishment', component: AggregatorReportsWritingReplenishmentComponent},
              {path: 'distribution-of-funds', component: AggregatorDistributionOfFundsComponent},
            ]
          }
      ]
    },
    {path: 'reports', component: ReportsComponent, canActivate: [AuthGuard],
      children: [
        {path: '', redirectTo: 'programs', pathMatch:  'full'},
        {path: 'programs', component: ReportsProgramsComponent,
          children: [
            {path : '', redirectTo: 'writing-history', pathMatch: 'full'},
            {path: 'writing-history', component: ReportsWritingHistoryComponent},
            {path: 'writing-replenishment', component: ReportsWritingReplenishmentComponent},
            {path: 'distribution-of-funds', component: DistributionOfFundsComponent},
            {path: 'revisions', component: RevisionHistoryComponent},
            {path: 'revision/:id', component: ReportRevisionComponent},
          ]
        },
        {path: 'aggregators', component: ReportsAggregatorsComponent,
          children: [
            {path : '', redirectTo: 'writing-history', pathMatch: 'full'},
            {path: 'writing-history', component: AggrReportsWritingHistoryComponent},
            {path: 'writing-replenishment', component: AggrReportsWritingReplenishmentComponent},
            {path: 'distribution-of-funds', component: AggrDistributionOfFundsComponent},
            {path: 'revisions', component: AggrRevisionHistoryComponent},
            {path: 'revision/:id', component: AggrReportRevisionComponent},
          ]
        },
      ]
    },
    {path: 'aggregator', component: FinAggregatorComponent, canActivate: [AuthGuard],
      children: [
        {path: '', redirectTo: 'account', pathMatch: 'full'},
        {path: 'list', component: FinListComponent},
        {path: 'add', component: FinAddComponent},
        {path: 'account', component: FinAccountComponent},
        {path: 'allocation', component: FinAllocationComponent},
        {path: 'edit/:id', component: FinEditComponent},
        {path: 'revisions', component: FinRevisionsComponent},
        {path: 'revision/:id', component: FinRevisionComponent},
        {path: 'show/:id', component: AgShowProgramComponent},
      ]
    },
  	{path: 'programs', component: ProgramsComponent, canActivate: [AuthGuard],
      children: [
        {path: '', redirectTo: 'account', pathMatch: 'full'},
        {path: 'list', component: ListComponent},
        {path: 'add', component: AddComponent},
        {path: 'account', component: AccountComponent},
        {path: 'allocation', component: AllocationComponent},
        {path: 'edit/:id', component: EditComponent},
        {path: 'revisions', component: RevisionsComponent},
        {path: 'refund', component: RefundComponent},
        {path: 'revision/:id', component: RevisionComponent},
        {path: 'show/:id', component: ShowProgramComponent}
      ]
    },
    {path: 'settings', component: SettingsComponent,
      children: [
        {path: '', redirectTo: 'common', pathMatch: 'full'},
        {path: 'common', component: SettingsCommonComponent},
        {path: 'users', component: FinUsersComponent,
          children: [
            {path: '', redirectTo: 'list', pathMatch: 'full'},
            {path: 'list', component: FinUsersListComponent},
            {path: 'create', component: FinUsersCreateComponent},
            {path: 'edit/:id', component: FinUsersEditComponent},
          ]
        },
      ]
    },
    {path: 'analyst', component: AnalystComponent, canActivate: [AuthGuard],
      children: [
        {path: '', redirectTo: 'balance-request', pathMatch: 'full'},
        {path: 'balance-request', component:BalanceRequestComponent,
          children: [
            {path: '', redirectTo: 'aggregator', pathMatch: 'full'},
            {path: 'aggregator', component: AggregatorComponent},
            {path: 'fin-institute', component: FinInstituteComponent},
            {path: 'bank', component: BankRequestComponent},
          ]
        },
        {path: 'refund', component:RefundRequestComponent,
          children: [
            {path: '', redirectTo: 'aggregator', pathMatch: 'full'},
            {path: 'aggregator', component: RefundAggregatorComponent},
            {path: 'fin-institute', component: RefundFinInstituteComponent}
          ]
        },
        {path: 'balance-operation', component:BalanceOperationRequestComponent,
          children: [
            {path: '', redirectTo: 'aggregator', pathMatch: 'full'},
            {path: 'aggregator', component: BalanceOperationAggregatorComponent},
            {path: 'fin-institute', component: BalanceOperationFinInstituteComponent}
          ]
        },
        {path: 'programs', component:AnalystProgramsRequestComponent,
          children: [
            {path: '', redirectTo: 'aggregator', pathMatch: 'full'},
            {path: 'aggregator', component: ProgramsAggregatorComponent},
            {path: 'show/:id', component: ShowAnalystProgramComponent},
            {path: 'fin-institute', component: ProgramsFinInstituteComponent}
          ]
        },
        {path: 'revision', component:RevisionAnalyticsComponent,
          children: [
            {path: '', redirectTo: 'fin-institute', pathMatch: 'full'},
            {path: 'aggregator', component: RevisionAggregatorComponent},
            {path: 'fin-institute', component: RevisionFinInstituteComponent},
            {path: 'show/:id', component: AnalystRevisionComponent},
          ]
        },
      ]
    }
]

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    BankComponent,
    BankMainComponent,
    LeadsComponent,
    GeneratorComponent,
    GeneratorMainComponent,
    GeneratorLeadsComponent,
    SettingsComponent,
    SettingsCommonComponent,
    FinUsersComponent,
    FinUsersListComponent,
    FinUsersCreateComponent,
    FinUsersEditComponent,
    GeneratorProgramsComponent,
    GeneratorListComponent,
    GeneratorRevisionsComponent,
    GeneratorRevisionComponent,
    GeneratorVisionsComponent,
    GeneratorVisionsNewComponent,
    GeneratorShowProgramComponent,
    GeneratorVisionsRejectedComponent,
    GeneratorVisionsAcceptedComponent,
    GeneratorVisionsShowComponent,
    GeneratorVisionsCreateComponent,
    BankLeadsComponent,
    BankSettingsComponent,
    CommonComponent,
    BankUsersComponent,
    BankUsersListComponent,
    BankUsersCreateComponent,
    BankUsersEditComponent,
    BankVisionsComponent,
    BankVisionsNewComponent,
    BankVisionsAcceptedComponent,
    BankVisionsRejectedComponent,
    BankVisionsShowComponent,
    BankAggregatorComponent,
    BankAggrAccountComponent,
    BankAggrAllocationComponent,
    BankAgShowProgramComponent,
    BankAggrEditComponent,
    BankAggrRevisionsComponent,
    BankAgrRevisionComponent,
    BankAggrListComponent,
    BankProgramsComponent,
    BankListComponent,
    BankListCompaignComponent,
    BankAddCampaignComponent,
    BankAddComponent,
    BankEditComponent,
    AggregatorsComponent,
    BankShowProgramComponent,
    BankEditCampaignComponent ,
    BankAccountComponent,
    BankAllocationComponent,
    BankRevisionsComponent,
    BankRevisionComponent,
    BankRefundComponent,
    RevisionComponent,
    AggregatorReportsWritingReplenishmentComponent,
    AggrReportsAggregatorsComponent ,
    AggregatorReportsWritingHistoryComponent,
    AggregatorDistributionOfFundsComponent,
    FinanceComponent,
    AggrEditComponent,
    AggregatorsAllocationComponent,
    FinanceProgramsListComponent,
    AggrRefundComponent,
    AggrShowProgramComponent,
    AggrMainComponent,
    FinanceAccountComponent,
    AggregatorAddProgramComponent,
    BalanceRequestComponent,
    ReportsWritingHistoryComponent,
    RefundAggregatorComponent,
    RefundFinInstituteComponent,
    BankRequestComponent,
    FinAllocationComponent,
    AnalystComponent,
    ShowAnalystProgramComponent,
    ShowProgramComponent,
    NavComponent,
    Money,
    DistributionOfFundsComponent,
    RevisionHistoryComponent,
    RefundComponent,
    ReportRevisionComponent,
    AggregatorComponent,
    FinInstituteComponent,
    AggrReportsWritingHistoryComponent,
    AggrReportsWritingReplenishmentComponent,
    AggrDistributionOfFundsComponent,
    AggrRevisionHistoryComponent,
    AggrReportRevisionComponent,
    ReportsWritingReplenishmentComponent,
    RefundRequestComponent,
    ReportsProgramsComponent,
    ReportsAggregatorsComponent,
    FinListComponent,
    FinEditComponent,
    FinRevisionComponent,
    AgShowProgramComponent,
    FinRevisionsComponent,
    FinAddComponent,
    EditComponent,
    LoginComponent,
    BalanceOperationRequestComponent,
    BalanceOperationAggregatorComponent,
    BalanceOperationFinInstituteComponent,
    RevisionAnalyticsComponent,
    RevisionAggregatorComponent,
    RevisionFinInstituteComponent,
    AnalystRevisionComponent,
    AnalystProgramsRequestComponent,
    ReportsComponent,
    ProgramsAggregatorComponent,
    ProgramsFinInstituteComponent,
    MainComponent,
    AccountComponent,
    ListComponent,
    AddComponent,
    RevisionsComponent,
    AllocationComponent,
    FinAggregatorComponent,
    ProgramsComponent,
    FinAccountComponent
  ],
  imports: [
  	BrowserModule,
  	FormsModule,
    // MyDatePickerModule,
    TextMaskModule,
    ChartsModule,
  	ReactiveFormsModule,
  	HttpClientModule,
    HttpModule,
  	// NgxChartsModule,
    ToastyModule.forRoot(),
  	BrowserAnimationsModule,
  	RouterModule.forRoot(
  	  appRoutes,
    ),
    NgbModule
  ],
  providers: [AppletService,AuthGuard,MessageService, CurrencyPipe ],
  bootstrap: [AppComponent]
})
export class AppModule { }

