import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class AggrRefundComponent implements OnInit {
	
	programs;
	selectedModel = 'all';
	list;
	showH = false;
	refundModal = false;
	reason = '';
	currentItem;
	refundSMS = false;
	SMSCode;
	paymentId;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
		this.checkCountry();	
		this.getList();
	}

	onchangeDate(t,v){
		
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getList(){
		this.currentItem = [];
		this.reason = "";
		this.app.get('/core/aggregator/program/?only_aggregator=true').subscribe(res => {
			this.list = res;
			this.showH = !res.length;
		})
	}

	refundItem(item){
		this.refundModal = true;
		this.currentItem = item;
	}

	refundConfirm(){
		if(!this.reason){
			this.msg.addToast('Укажите причину отзыва','error');
		}else{
			let options = {
				program: this.currentItem.id,
				reason: this.reason 
			}
			this.app.post('/core/aggregator/refund/', options).subscribe(res => {
				this.refundSMS = true;
				this.refundModal = false;
				this.paymentId = res.activation.id;
			})
		}
	}

	onCodeEnter(){
		this.app.enterCodeSMS(this.paymentId, this.SMSCode).subscribe(res => {
			this.getList();
			this.refundSMS = false;
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

}