import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.css']
})
export class BankAllocationComponent implements OnInit {

	paymentAmount;
	paymentModal = false;
	list;
	selectedModel = -1;
	paymentSMS = false;
	allocationList;
	country;

	balanceAllocationID;

	SMSCode;

	userBalance = 0;
	showH = false;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.checkCountry();
		this.app.get('/core/bank_aggregator/program/?only_aggregator=false').subscribe(res => {
			this.list = [];
			for(let item of res.results){
				if(item.status === 'ACTIVE'){
					this.list.push(item);
				}
			}
		})
		this.getList();
		this.getBalance();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getList(){
		this.app.get('/core/bank_aggregator/balance_allocation/').subscribe(res => {
			this.allocationList = res.results;
			this.showH = !this.allocationList.length
		})
	}

	paymentConfirm(){
		let d = {
			program : this.selectedModel, 
			amount: this.paymentAmount
		}
		this.app.post('/core/bank_aggregator/balance_allocation/', d).subscribe(res => {
			this.paymentModal = false;
			this.paymentAmount = null;
			this.paymentSMS = true;
			this.balanceAllocationID = res.activation.id;
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	getBalance(){
		this.app.getUserByToken().subscribe(res => {
			this.userBalance = res.user.bank_aggregator.balance;
		})
	}


	onCodeEnter(){
		this.app.enterCodeSMS(this.balanceAllocationID, this.SMSCode).subscribe(res => {
			this.paymentSMS = false;
			this.getList();
			this.getBalance();
			this.SMSCode = null;
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

}