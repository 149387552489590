import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';


@Component({
  selector: 'app-aggregator',
  templateUrl: './aggregator.component.html',
  styleUrls: ['./aggregator.component.css']
})
export class AggregatorComponent implements OnInit {
  
  paymentAmount = 0;
  editedItem;
  settings = "?only_aggregator=true";
  list = [];
  editPayment = false;
  aggregatorList;
  country;
  page = 1;
	collectionSize = '';
	limit = 15;
	offset = 0;

  filtering = {
    aggregator: '',
    date_created_begin: '',
    date_created_end: '',
    status: '',
    country: ''
  }
  

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router) {
  }

  ngOnInit() {
    this.getList();
    this.getFinInstituteList();
  }

  getFinInstituteList(){
    this.app.getAggregatorList().subscribe(res =>{
      this.aggregatorList = res;
    })
  }

  onChangeFin(val){
    if(val === 'All'){
      this.filtering.aggregator = '';
    }else{
      this.filtering.aggregator = val;
    }
    this.getList();
  }

  onChangeDateStart(val){
    if(val){
      this.filtering.date_created_begin = new Date(val).toISOString();
    }else {
      this.filtering.date_created_begin = '';
    }
    this.getList();
  }

  onChangeDateEnd(val){
    if(val){
      this.filtering.date_created_end = new Date(val).toISOString();
    }else {
      this.filtering.date_created_end = '';
    }
    this.getList();
  }

  onChangeStatus(val){
    if(val === 'ALL'){
      this.filtering.status = '';
    }else{
      this.filtering.status = val;
    }
    this.getList();
  }

  onChangeCountry(val){
    if(val === 'All'){
      this.filtering.country = '';
    }else{
      this.filtering.country = val;
    }
    this.getList();
  }

  loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getList();
		}
	}

  getList(){
    let filter = '';
    this.app.getStats().subscribe(res => {
      
    });
    for(let key of Object.keys(this.filtering)){
      if(this.filtering[key]){
        filter += '&' + key + '=' + this.filtering[key];
      }
    }

		this.offset = (this.page - 1) * this.limit;
    this.app.getAnalystList(this.settings + filter, this.limit, this.offset).subscribe(res => {
      this.list = res.results;
      this.collectionSize = res.count;
    })
  }

  checkCountry(country) {
    if(country === 'KZ') {
      this.country = 'тг';
    } else if (country === 'KG') {
      this.country = 'сом';
    } else if (country === 'RU') {
      this.country = 'рублей';
    }
  }

  reject(id){
    this.app.changeListStatus(id,'reject').subscribe(res =>{
      this.getList();
    })
  }

  approve(id){
    this.app.changeListStatus(id,'approve').subscribe(res =>{
      this.getList();
    }) 
  }

  showModal(item){
    this.editPayment = true;
    this.editedItem = item;
    this.paymentAmount = item.amount;
  }

  onChangePayment(){
    this.app.updateListStatus(this.editedItem.id, this.paymentAmount).subscribe(res =>{
      this.getList();
      this.editPayment = false;
    }) 
  }
}