import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-programs-bank',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class GeneratorProgramsComponent implements OnInit {
	
	activeTab = 3;
	list;
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
	}

}