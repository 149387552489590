import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-add-bank',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class BankAddComponent implements OnInit {
	
	cities;
	showed = 0;
	additionalSources = "";

	current = Date.now();
	campaignList;
	leadList;
	confg = {
		cities:[],
		date_end:"",
		date_start:"",
		period_type: 'EVERY_DAY',
		name:"",
		traffic: null,
		payment_type: "LEAD",
		program_type: "DEBET_CARD",
		is_active: "true",
		with_scoring: false,
		url: "",
		campaign: null,
		lead_generator: null,
		price: null
	}

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {

		this.app.getCities().subscribe(res => {
			this.cities = res;
		})

		this.getCampaign();
		this.getLeads();
	}

	getCampaign(){
		this.app.get('/core/campaign/').subscribe(res => {
			this.campaignList = res;
		})
	}

	getLeads(){
		this.app.get('/core/lead_generator/list/').subscribe(res => {
			this.leadList = res;
		})
	}


	changeCity(id){
		var index = this.confg.cities.indexOf(id);
		if(index === -1){
			this.confg.cities.push(id);
		}else{
			this.confg.cities.splice(index,1);
		}
	}

	onSave(){
		// this.confg.additional_lead_sources = [];
		// this.confg.additional_lead_sources.push(this.additionalSources);
		this.app.post('/core/bank_aggregator/program/', this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/bank/programs/list']);
			}
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
		
	}

	changeShowed(id){
		if(this.showed === id){
			this.showed = 0;	
		}else{
			this.showed = id;
		}
	}
}