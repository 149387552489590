import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-programsaggregator',
  templateUrl: './aggregator.component.html',
  styleUrls: ['./aggregator.component.css']
})
export class ProgramsAggregatorComponent implements OnInit {
  
  paymentAmount = 0;
  editedItem;
  settings = "?only_aggregator=true";
  list = [];
  editPayment = false;
  aggregatorList;

  filtering = {
    fin_institute: '',
    aggregator: '',
    date_created_begin: '',
    date_created_end: '',
    status: '',
    country: ''
  }
  finInsList;
  url = 'program';
  page = 1;
	collectionSize = '';
	limit = 15;
	offset = 0;

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router,
            public msg: MessageService) {
  }

  ngOnInit() {
    this.getList();
    this.getFinInstituteList();
  }

  getFinInstituteList(){
    this.app.getFinInstituteList().subscribe(res =>{
      this.finInsList = res;
    })
    this.app.getAggregatorList().subscribe(res =>{
      this.aggregatorList = res;
    })
  }

  onChangeFin(val){
    if(val === 'All'){
      this.filtering.fin_institute = '';
    }else{
      this.filtering.fin_institute = val;
    }
    this.getList();
  }

  onChangeAggr(val){
    if(val === 'All'){
      this.filtering.aggregator = '';
    }else{
      this.filtering.aggregator = val;
    }
    this.getList();
  }

  onChangeDateStart(val){
    if(val){
      this.filtering.date_created_begin = new Date(val).toISOString();
    }else {
      this.filtering.date_created_begin = '';
    }
    this.getList();
  }

  onChangeDateEnd(val){
    if(val){
      this.filtering.date_created_end = new Date(val).toISOString();
    }else {
      this.filtering.date_created_end = '';
    }
    this.getList();
  }

  onChangeCountry(val){
    if(val === 'All'){
      this.filtering.country = '';
    }else{
      this.filtering.country = val;
    }
    this.getList();
  }

  onChangeStatus(val){
    if(val === 'ALL'){
      this.filtering.status = '';
    }else{
      this.filtering.status = val;
    }
    this.getList();
  }

  loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getList();
		}
	}

  getList(){
    let filter = '';
    this.app.getStats().subscribe(res => {
      
    });
    for(let key of Object.keys(this.filtering)){
      if(this.filtering[key]){
        filter += '&' + key + '=' + this.filtering[key];
      }
    }

		this.offset = (this.page - 1) * this.limit;
    this.app.getList(this.url, this.settings + filter, this.limit, this.offset).subscribe(res => {
      this.list = res.results;
      this.collectionSize = res.count;
    })
  }

  reject(id){
    this.app.changeListItemStatus(this.url,id,'reject').subscribe(res =>{
      this.getList();
    })
  }

  approve(id){
    this.app.changeListItemStatus(this.url,id,'approve').subscribe(res =>{
      this.getList();
    }) 
  }

  showModal(item){
    this.editPayment = true;
    this.editedItem = item;
    this.paymentAmount = item.price;
  }

  onChangePayment(item){
    let settings = {
      status: item.status,
      price: this.paymentAmount
    }
    this.app.updateProgramsAnalyst(this.url, settings, item.id).subscribe(res =>{
      this.getList();
      this.editPayment = false;
      this.app.getStats();
    }, error => {
      this.msg.addToast(error.message, 'error');
    }) 
  }
}