import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../_services/applet.service';
import {MessageService} from '../_services/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	activate = false;
	user;
	code = "";
	number;
	phoneNumber;

	public phone;
	public mask;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private msg: MessageService,) {
	}

	ngOnInit() {
		this.checkAuth();
		let url = window.location.href;
		if(url.indexOf('.kz') != -1){
			this.number = '+7'
  			this.mask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/,' ', /\d/, /\d/];
		} else if(url.indexOf('.kg') != -1){
			this.number = '+996'
			this.mask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
		} else if(url.indexOf('.ru') != -1){
			this.number = '+7'
			this.mask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/,' ', /\d/, /\d/];
		}else {
	      this.number = '+7'
	      this.mask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/,' ', /\d/, /\d/];
	    }
	}

	onLoginForm(){
		let number = this.number + this.phone.replace(/\D+/g, '');
		this.app.authenticate(number).subscribe(res => {
			if(res.code === 0){
				this.activate = true;
				this.user = res.result.activation;
			}
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	checkAuth(){
		if(this.app.checkAuth()){
			if(this.app.getUser().user_type === 'FIN_INSTITUTE'){
				this.router.navigate(['/main']);
			}else if(this.app.getUser().user_type === 'ANALYST'){
				this.router.navigate(['/analyst']);
			}else if(this.app.getUser().user_type === 'BANK_AGGREGATOR'){
				if(this.app.getUser().institution_role === 'OPERATOR'){
					this.router.navigate(['/bank/leads']);
				}else{
					this.router.navigate(['/bank']);
				}
			}else if(this.app.getUser().user_type === 'LEAD_GENERATOR'){
				this.router.navigate(['/generator']);
			}else{
				this.router.navigate(['/aggregators']);
			}
		}
	}

	onCodeEnter(){
		this.app.enterCode(this.user.id, this.code).subscribe(res => {
			this.checkAuth();
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

}
