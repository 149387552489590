import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../../_services/applet.service';


@Component({
  selector: 'app-list-bank',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class BankUsersListComponent implements OnInit {

  users;
  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
    this.getUsers();
  }

  getUsers(){
    this.app.get('/core/bank_aggregator/user/').subscribe(res => {
      this.users = res;
    })
  }

}