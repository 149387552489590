import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';
import {MessageService} from '../../_services/message.service';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.css']
})

  

export class SettingsCommonComponent implements OnInit {


	distribution_type = null;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private msg: MessageService,) {
	}

	ngOnInit() {
		this.getDistribution();
	}

	getDistribution(){
		this.app.getUserByToken().subscribe(res => {
			console.log(res.user)
			this.distribution_type = res.user.fin_institute.distribution_type
		})
	}

	changeDistribution(){
		let data = {
			distribution_type: this.distribution_type
		}
		this.app.put('/core/fin_institute/distribution/distribution/',data).subscribe(res => {
			this.msg.addToast('Изменения сохранены', 'success');
		})
	}

}