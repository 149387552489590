import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-list-bank',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class BankAggrListComponent implements OnInit {
	
	list;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
		this.checkCountry();
		this.getProgram();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getProgram(){
		this.app.get('/core/bank_aggregator/program/?only_aggregator=true').subscribe(res=>{
			this.list = res.results;
		})
	}

	deleteProgram(id){
		this.app.deleteProgram(id).subscribe(res => {
			this.getProgram();
		})
	}

}