import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../_services/applet.service';


@Component({
  selector: 'app-aggregators',
  templateUrl: './aggregators.component.html',
  styleUrls: ['./aggregators.component.css']
})
export class AggregatorsComponent implements OnInit {
  

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
  }

}