import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.css']
})
export class AggregatorsAllocationComponent implements OnInit {

	paymentAmount;
	paymentModal = false;
	list;
	selectedModel = -1;
	paymentSMS = false;
	allocationList;
	country;

	balanceAllocationID;

	SMSCode;

	userBalance = 0;
	aggregators;
	selectedAggregator = -1;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.checkCountry();
		this.getList();
		this.getBalance();
		this.getFinInstitute();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тенге';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getList(){
		this.app.get('/core/aggregator/balance_allocation/').subscribe(res => {
			this.allocationList = res.results;
		})
	}

	onchangeAggr(){
		if(this.selectedAggregator !== -1){
			this.getProgramsById(this.selectedAggregator);
		}
	}

	getFinInstitute(){
		this.app.get('/core/fin_institute/list/').subscribe(res => {
			this.aggregators = res;
		})
	}

	getProgramsById(id){
		this.app.get('/core/aggregator/program/?fin_institute='+id).subscribe(res => {
			this.list = [];
			for(let item of res.results){
				if(item.status === 'ACTIVE'){
					this.list.push(item);
				}
			}
		})
	}

	paymentConfirm(){
		this.app.post("/core/aggregator/balance_allocation/",
			{fin_institute: this.selectedAggregator, amount : this.paymentAmount, program : this.selectedModel })
			.subscribe(res => {
				this.paymentModal = false;
				this.paymentAmount = null;
				this.paymentSMS = true;
				this.balanceAllocationID = res.activation.id;
		})
	}

	getBalance(){
		this.app.getUserByToken().subscribe(res => {
			this.userBalance = res.user.aggregator.balance;
		})
	}

	onCodeEnter(){
		this.app.post("/auth/activation/"+this.balanceAllocationID+'/activate/',{code:this.SMSCode }).subscribe(res => {
			this.paymentSMS = false;
			this.getList();
		}, error => {
			this.msg.addToast(error.message,'error')
		})
	}

}