import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';
import {MessageService} from '../../_services/message.service';


@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.css']
})
export class FinAllocationComponent implements OnInit {

	paymentAmount;
	paymentModal = false;
	list = [];
	selectedModel = -1;
	paymentSMS = false;
	allocationList;
	country;

	balanceAllocationID;

	SMSCode;

	userBalance = 0;
	aggregators;
	selectedAggregator = 'all';

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.checkCountry();
		this.getList();
		this.getBalance();
		this.getAggregatorList();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getList(){
		// this.app.getBalanceAllocationList().subscribe(res => {
		// })
		this.app.get('/core/fin_institute/balance_allocation/?only_aggregator=true').subscribe(res => {
			this.allocationList = res.results;
		})
	}

	onchangeAggr(){
		this.list = [];
		if(this.selectedAggregator !== 'all'){
			this.getProgramsById(this.selectedAggregator);
		}
	}

	getAggregatorList(){
		this.app.getAggregatorList().subscribe(res => {
			this.aggregators = res;
		})
	}

	getProgramsById(id){
		this.app.getProgramsFin(id).subscribe(res => {
			this.list = [];
			for(let item of res.results){
				if(item.status === 'ACTIVE'){
					this.list.push(item);
				}
			}
		})
	}

	paymentConfirm(){
		this.app.createAllocationAggr(this.selectedAggregator, this.selectedModel, this.paymentAmount).subscribe(res => {
			this.paymentModal = false;
			this.paymentAmount = null;
			this.paymentSMS = true;
			this.balanceAllocationID = res.activation.id;
		})
	}

	getBalance(){
		this.app.getUserByToken().subscribe(res => {
			this.userBalance = res.user.fin_institute.total_aggregator_balance;
		})
	}


	onCodeEnter(){
		this.app.enterCodeSMS(this.balanceAllocationID, this.SMSCode).subscribe(res => {
			this.paymentSMS = false;
			this.getList();
			this.getBalance();
			this.SMSCode = null;
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

}