import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundRequestComponent implements OnInit {
  

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
  }

}