import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-reports_aggregators',
  templateUrl: './reports_aggregators.component.html',
  styleUrls: ['./reports_aggregators.component.css']
})
export class ReportsAggregatorsComponent implements OnInit {
	
	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		// Object.assign(this, {single, multi}) 
	}

	ngOnInit() {
	}

}