import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';


@Component({
  selector: 'app-writing_replenishment',
  templateUrl: './writing_replenishment.component.html',
  styleUrls: ['./writing_replenishment.component.css']
})

export class ReportsWritingReplenishmentComponent implements OnInit {

	showH = false;
	list;
	programId = '';
  	filtering = {
	    date_created_begin: '',
	    date_created_end: '',
	}

	resetF = {
		date_created_begin: '',
	    date_created_end: '',
	}	
	offset = 0;	
	page = 1;
	collectionSize = '';
	limit = 15;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router) {
		this.getList();
	}

	ngOnInit() {
		this.checkCountry();	
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	reset(){
		this.resetF = {
			date_created_begin: '',
		    date_created_end: '',
		}

		this.filtering = {
			date_created_begin: '',
		    date_created_end: '',	
		}

		this.getList();
	}

	loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getList();
		}
	}

	getList(){
		let filter = '';
	    for(let key of Object.keys(this.filtering)){
	      if(this.filtering[key]){
	        filter += '&' + key + '=' + this.filtering[key];
	      }
	    }

		this.offset = (this.page - 1) * this.limit;
		this.app.get('/core/fin_institute/balance_operation/?only_aggregator=false&operation_type=DEBIT' + '&limit=' + this.limit + '&offset=' + this.offset + filter).subscribe(res => {
			this.showH = !res.results.length;
			this.list = res.results;
			this.collectionSize = res.count;
		})
	}

  	onchangeDate(val, type){
		if(val){
		  this.filtering[type] = new Date(val).toISOString();
		}else {
		  this.filtering[type] = '';
		}
		this.getList();
	}


	doExcel() {
		let filter = '';
	    for(let key of Object.keys(this.filtering)){
	      if(this.filtering[key]){
	        filter += '&' + key + '=' + this.filtering[key];
	      }
	    }

	    let settings = "/core/fin_institute/balance_operation/export/?only_aggregator=false&limit=1000&operation_type=CREDIT";

	    this.app.getReportExcel(settings+filter).subscribe(data => {
	        this.downloadFile(data._body);
	    });
  	}
	
	downloadFile(data) {
		let today: number = Date.now();
	    let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
	    var blobURL = window.URL.createObjectURL(blob);
	    var anchor = document.createElement("a");
	    anchor.download = "report"+today+".xls";
	    anchor.href = blobURL;
	    anchor.click();
  	}
}