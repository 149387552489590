import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowAnalystProgramComponent implements OnInit {
	id;
	program;
	leads;
	expanded = false;
	disabled = false;
	country;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.id = this.route.snapshot.params['id'];
		this.app.get('/core/analyst/program/' + this.id).subscribe(res => {
			this.program = res;
			this.getLeads(res.id);
			this.getLeadsFinInstitute(res.id);
		})
	}

	checkCountry(country) {
		if(country === 'KZ') {
		  this.country = 'тг';
		} else if (country === 'KG') {
		  this.country = 'сом';
		} else if (country === 'RU') {
		  this.country = 'рублей';
		}
	}

	toggle(){
	     this.expanded = !this.expanded;
	}


	getLeads(id){
		this.app.get('/core/analyst/lead/?only_aggregator=true&program='+id).subscribe(res =>{
			this.leads = res;
		})
	}

	getLeadsFinInstitute(id){
		this.app.get('/core/analyst/lead/?only_aggregator=false&program='+id).subscribe(res =>{
			this.leads = res;
		})
	}

	sendEmail(){
		this.disabled = true;
		this.app.get('/core/analyst/program/' + this.id +'/send_via_email/').subscribe(res =>{
			this.msg.addToast('Сообщение отправлено.', 'success');
			this.disabled = false;
		})
	}
}