import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../../_services/applet.service';
import {MessageService} from '../../../../_services/message.service';

@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class BankUsersEditComponent implements OnInit {

  id;
  user;
  public mask = [/\+/,/[7]/,' ',/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/,' ', /\d/, /\d/];
  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router,
            public msg: MessageService) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getUser(this.id);
  }

  getUser(id){
    this.app.get('/core/bank_aggregator/user/' + this.id).subscribe(res => {
      this.user = res;
    })
  }


  saveUser(){

    if(this.user.phone === "+7"){
      this.user.phone = '';
    }else{
      let number = this.user.phone.replace(/\D+/g, '');
      this.user.phone =  '+' + number;
    }
    this.app.put('/core/bank_aggregator/user/'+ this.id + '/', this.user).subscribe(res => {
      // this.router.navigate(['/settings/users/list']);
      this.msg.addToast('Изменения сохранены', 'success');
    },error => {
      this.msg.addToast(error.message, 'error');
    })
  }

}