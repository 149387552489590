import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AppletService } from '../_services/applet.service';
import { MessageService } from '../_services/message.service';

@Component({
	selector: 'app-leads',
	templateUrl: './leads.component.html',
	styleUrls: ['./leads.component.css']
})


export class LeadsComponent implements OnInit {

	programs;
	cities;
	statuses;
	list = [];
	managers;
	page = 1;
	collectionSize = '';
	limit = 15;
	offset = 0;
	commentModal = false;
	commentItem;
	selectedProgram = 1;

	filtering = {
		program: 'all',
		city: 'all',
		processing_status: 'all',
		date_created_begin: '',
		date_created_end: '',
		operator: 'all'
	}

	show = [];
	
	constructor(private route: ActivatedRoute,
		public app: AppletService,
		public router: Router,
		private msg: MessageService) {
	}

	ngOnInit() {
		this.getLeadFields();
		this.getProgram();
		this.getList();
		this.getCities();
		this.getManagers();
	}

	getLeadFields(){
		this.app.getLeadFields().subscribe(res => {
			for(let item of res){
				item.id = null;
				this.show.push(item);
			}
		})
	}

	onChangeStatus(item, val, ) {
		let option = {
			leads: [item.id],
			processing_status: val
		}

		this.app.post('/core/fin_institute/lead/update_processing_status/', option).subscribe(res => {
			if(item.operator === null){
				this.onSubmitManager(item.id, this.app.getUser().id)
			}else{
				this.getList();
			}
		}, error => {
			console.log(error);
		})
	}


	onchangeDate(val, type) {
		if (val) {
			this.filtering[type] = new Date(val).toISOString();
		} else {
			this.filtering[type] = '';
		}
		this.getList();
	}


	onSelectDict(event) {
		console.log(event);
	}

	getCities() {
		this.app.get('/core/city/').subscribe(res => {
			this.cities = res;
		})
	}

	loadPage(page: number) {
		if (page !== this.page) {
			this.page = page;
			this.getList();
			this.getManagers();
		}
	}

	getList() {
		let filter = '';
		for (let key of Object.keys(this.filtering)) {
			if (this.filtering[key]) {
				if (this.filtering[key] !== 'all') {
					filter += '&' + key + '=' + this.filtering[key];
				}
			}
		}
		if (this.filtering.program === 'all'){
			this.selectedProgram = 1
		}else{
			this.selectedProgram = 2
		}
		this.offset = (this.page - 1) * this.limit;
			this.app.get('/core/fin_institute/lead/?only_aggregator=false&limit=' + this.limit + '&offset=' + this.offset + filter).subscribe(res => {
				this.list = res.results;
				this.collectionSize = res.count;
				for (var i = 0; i < this.list.length; ++i) {
					for(let item of this.show){
						console.log(this.list[i].info[item.key])
						if(this.list[i].info[item.key]){
							item.id = 1;
						}
					}
				}
			})
	}

	getManagers() {
		this.app.get('/core/fin_institute/operator/').subscribe(res =>{
			this.managers = res;
	    })
	  }

  onSubmitManager(id, value) {
  	console.log(id,value)
	  let params = {
		  lead: id,
		  operator: value
	  }
	  this.app.post('/core/fin_institute/lead/change_operator/', params).subscribe(res => {
		this.getList();
	  }, error => {
		  this.msg.addToast(error.message, 'error');
		  console.log(error);
	  })
  }

	showModalComment(item){
		this.commentModal = true;
		this.commentItem = item;
	  }
	
	  onSubmitComment(id, comment){
		let params = {
		  lead: id,
		  comment: comment
		}
		this.app.post('/core/fin_institute/lead/update_comment/', params).subscribe(res => {
			this.msg.addToast(res.message, 'success');
		}, error => {
			this.msg.addToast(error.message, 'error');
			console.log(error);
		})
	  }

	getProgram() {
		this.app.get('/core/fin_institute/program/?only_aggregator=false').subscribe(res => {
			this.programs = res.results;
		})
	}

	doExcel() {
		let filter = '?p=1';
		for (let key of Object.keys(this.filtering)) {
			if (this.filtering[key]) {
				if (this.filtering[key] !== 'all') {
					filter += '&' + key + '=' + this.filtering[key];
				}
			}
		}

		let settings = '/core/fin_institute/lead/export_leads/';
		this.app.getReportExcel(settings + filter).subscribe(data => {
			this.downloadFile(data._body);
		});
	}

	downloadFile(data) {
		let today: number = Date.now();
		let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
		var blobURL = window.URL.createObjectURL(blob);
		var anchor = document.createElement("a");
		anchor.download = "report" + today + ".xls";
		anchor.href = blobURL;
		anchor.click();
	}
}
