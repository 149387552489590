import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../_services/applet.service';


@Component({
  selector: 'app-users-bank',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class FinUsersComponent implements OnInit {

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
  }

}