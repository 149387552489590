import {Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-visions-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class GeneratorVisionsCreateComponent implements OnInit {

	form: FormGroup;
	loading: boolean = false;
	@ViewChild('fileInput*') fileInput: ElementRef;

	programs;
	bankList;

	vision = {
		name: null,
		bank_aggregator: null,
		program: null,
		vision_files : [],
		utm_source: null,
		utm_medium: null,
	}

	images = [];

	utmSources;
	utmMediums;
	comment;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService,
	      		private fb: FormBuilder,) {
		this.createForm();
	}

	ngOnInit() {
		this.getBankList();
		this.app.get('/core/utm_source/').subscribe(res => {
			this.utmSources = res;
		})
		this.app.get('/core/utm_medium/').subscribe(res => {
			this.utmMediums = res;
		})
	}

	createForm() {
		this.form = this.fb.group({
		image: [null,Validators.required]
		});
	}

	private prepareSave(): any {
		let input = new FormData();
		input.append('image', this.form.get('image').value);
		return input;
	}

	onFileChange(event) {
	    if(event.target.files.length > 0) {
	      let file = event.target.files[0];
	      this.form.get('image').setValue(file);
	      this.onSubmitf();
	    }
	}

	clearFile() {
		this.form.get('image').setValue(null);
		this.fileInput.nativeElement.value = '';
	}

	onSubmitf() {
		const formModel = this.prepareSave();
		this.loading = true;		
		this.app.uploadFile('/core/lead_generator/banner_file/',formModel).subscribe(res => {
			this.images.push(res);
			this.loading = false;
		}, error => {
			this.msg.addToast( error.message, 'error');
			this.loading = false;
		})
	}

	getProgram() {
		this.app.get('/core/lead_generator/program/?bank_aggregator='+ this.vision.bank_aggregator).subscribe(res => {
			this.programs = res.results;
		})
	}

	delete(i){
		this.images.splice(i,1);
	}

	getBankList() {
		this.app.get('/core/bank_aggregator/list/').subscribe(res => {
			this.bankList = res;
		})
	}

	onSubmit(){

	}


	createVision(){
		for(let item of this.images){
			this.vision.vision_files.push({'banner':item.uuid, 'comment': this.comment})
		}
		this.app.post('/core/lead_generator/vision/', this.vision).subscribe(res => {
			this.router.navigate(['/generator/visions/show/' + res.id]);
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}
}
