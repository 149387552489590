import {Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.css']
})
export class AggrReportRevisionComponent implements OnInit {
	
	id;
	revision;
	changingIds = [];
	showH = false;
	country;

	// LEAD_ACCEPTED
	// LEAD_REJECTED

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		private fb: FormBuilder) {
	}

	ngOnInit() {
		this.checkCountry();
		this.id = this.route.snapshot.params['id'];
		this.getList();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
			if(user.country === 'KZ') {
				this.country = 'тг';
			} else if (user.country === 'KG') {
				this.country = 'сом';
			} else if (user.country === 'RU') {
				this.country = 'рублей';
			}
	}

	getList(){
		this.app.getSingleRevision(this.id).subscribe(res => {
			this.revision = res;
			this.showH = !res.length;
		})
	}

	changeLeads(id){
		var index = this.changingIds.indexOf(id);
		if(index === -1){
			this.changingIds.push(id);
		}else{
			this.changingIds.splice(index,1);
		}
	}

	changeStatus(status){
		this.app.changeStatus(status, this.changingIds).subscribe(res => {
			this.getList();
			this.changingIds = [];
		})
	}

	doExcel() {
		let link = "/core/fin_institute/revision/" + this.id + "/export_leads/";
	    this.app.getReportExcel(link).subscribe(data => {
	        this.downloadFile(data._body);
	    });
  	}
	
	downloadFile(data) {
		let today: number = Date.now();
	    let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
	    var blobURL = window.URL.createObjectURL(blob);
	    var anchor = document.createElement("a");
	    anchor.download = "report"+today+".xls";
	    anchor.href = blobURL;
	    anchor.click();
  	}

}