import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../../_services/applet.service';
import {MessageService} from '../../../../_services/message.service';

@Component({
  selector: 'app-create-bank',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class BankUsersCreateComponent implements OnInit {

  user = {
    full_name: null,
    is_active: true,
    phone: '+7',
    institution_role : "",
  }

  public mask = [/\+/,/[7]/,' ',/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/,' ', /\d/, /\d/];

  constructor(private route: ActivatedRoute,
            public app: AppletService,
            public router: Router,
            public msg: MessageService) {
    // Object.assign(this, {single, multi}) 
  }

  ngOnInit() {
  }

  createUser(){

    if(this.user.phone === "+7"){
      this.user.phone = '';
    }else{
      let number = this.user.phone.replace(/\D+/g, '');
      this.user.phone =  '+' + number;
    }

    this.app.post('/core/bank_aggregator/user/', this.user).subscribe(res => {
      this.router.navigate(['/bank/settings/users/edit/' + res.id]);
      this.msg.addToast('Пользователь создан', 'success');
    },error => {
      this.msg.addToast(error.message, 'error');
    })
  }

}