import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';

@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class BankEditComponent implements OnInit {
	
	cities;
	id;

	confg;
	campaignList;
	leadList;

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.getCampaign();
		this.getLeads();
		this.id = this.route.snapshot.params['id'];
		this.app.get('/core/bank_aggregator/program/' + this.id).subscribe(res => {
			let b = {
				cities: res.cities,
				date_end: res.date_end,
				date_start: res.date_start,
				period_type: res.period_type,
				name: res.name,
				traffic: res.traffic,
				payment_type: res.payment_type,
				program_type: res.program_type,
				is_active: res.is_active,
				script: res.script,
				with_scoring: res.with_scoring,
				url: res.url,
				lead_generator: res.lead_generator.id,
				campaign: res.campaign.id,
				price: res.price
			}
			
			let cities = [];
			for(let item of b.cities){
				cities.push(item.id*1);
			}
			b.cities = cities;

			this.confg = b;
		})

		this.app.getCities().subscribe(res => {
			this.cities = res;
		})

	}

	getCampaign(){
		this.app.get('/core/campaign/').subscribe(res => {
			this.campaignList = res;
		})
	}

	getLeads(){
		this.app.get('/core/lead_generator/list/').subscribe(res => {
			this.leadList = res;
		})
	}

	changeCategory(id){
		var index = this.confg.categories.indexOf(id);
		if(index === -1){
			this.confg.categories.push(id);
		}else{
			this.confg.categories.splice(index,1);
		}
	}

	changeLeadFields(id){
		var index = this.confg.lead_fields.indexOf(id);
		if(index === -1){
			this.confg.lead_fields.push(id);
		}else{
			this.confg.lead_fields.splice(index,1);
		}
	}

	changeLeadSource(id){
		var index = this.confg.lead_sources.indexOf(id);
		if(index === -1){
			this.confg.lead_sources.push(id);
		}else{
			this.confg.lead_sources.splice(index,1);
		}
	}

	changeCity(id){
		var index = this.confg.cities.indexOf(id);
		if(index === -1){
			this.confg.cities.push(id);
		}else{
			this.confg.cities.splice(index,1);
		}
	}

	onSave(){
		this.app.put('/core/bank_aggregator/program/' + this.id + '/',this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/bank/programs/list']);
			}
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

}