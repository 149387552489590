import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { MessageService } from '../../../_services/message.service';
import { AppletService } from '../../../_services/applet.service';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css']
})
export class BankAccountComponent implements OnInit {

	paymentAmount;
	paymentModal = false;
	SMSCode;
	paymentSMS = false;
	paymentId;
	userBalance = 0;
	country;

	balanceList;
	debitList;
	formattedAmount;
	payment;
	showH = false;
	showY = false;

	mask = function (n) {
		return n.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}

	constructor(private route: ActivatedRoute,
		public app: AppletService,
		public router: Router,
		public CurrencyPipe: CurrencyPipe,
		public msg: MessageService) {
	}

	ngOnInit() {
		this.checkCountry();
		this.getBalanceList();
		this.getBalance();
		this.getDebit();
	}

	checkCountry() {
		let user = JSON.parse(window.localStorage.getItem('user'));
		if (user.country === 'KZ') {
			this.country = 'тг';
		} else if (user.country === 'KG') {
			this.country = 'сом';
		} else if (user.country === 'RU') {
			this.country = 'рублей';
		}
	}

	paymentConfirm() {
		this.app.post('/core/bank_aggregator/balance_request/',{amount: this.paymentAmount}).subscribe(res => {
			this.paymentModal = false;
			this.paymentAmount = null;
			this.paymentSMS = true;
			this.paymentId = res.activation.id;
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	getBalance() {
		this.app.getUserByToken().subscribe(res => {
			this.userBalance = res.user.bank_aggregator.balance;
		})
	}

	getDebit() {
		this.app.get('/core/bank_aggregator/balance_operation/?only_aggregator=false&operation_type=CREDIT').subscribe(res => {
			this.debitList = res.results;
			this.showH = !res.results.length;
		})
	}

	onCodeEnter() {
		this.app.enterCodeSMS(this.paymentId, this.SMSCode).subscribe(res => {
			this.paymentSMS = false;
			this.getBalanceList();
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
	}

	getBalanceList() {
		this.app.get('/core/bank_aggregator/balance_request/?only_bank_aggregator=false&limit=5').subscribe(res => {
			this.balanceList = res.results;
			this.showY = !res.results.length;
		})
	}
}