import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {AppletService} from '../../../_services/applet.service';
import {MessageService} from '../../../_services/message.service';


@Component({
  selector: 'app-add-bank',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class BankAddCampaignComponent implements OnInit {
	
	showed = 0;
	additionalSources = "";
	categories;
	leadSource;
	leadFields;

	current = Date.now();
	leadList;
	confg = {
		name:"",
		categories: [],
		lead_sources: [],
		lead_fields: []
	}

	constructor(private route: ActivatedRoute,
	      		public app: AppletService,
	      		public router: Router,
	      		public msg: MessageService) {
	}

	ngOnInit() {
		this.app.getLeadFields().subscribe(res => {
			this.leadFields = res;
		})

		this.app.getLeadSource().subscribe(res => {
			this.leadSource = res;
		})

		this.app.getCategories().subscribe(res => {
			this.categories = res;
		})
	}


	onSave(){
		this.app.post('/core/bank_aggregator/campaign/', this.confg).subscribe(res => {
			if(res){
				this.router.navigate(['/bank/programs/campaign']);
			}
		}, error => {
			this.msg.addToast(error.message, 'error');
		})
		
	}

	changeCategory(id){
		var index = this.confg.categories.indexOf(id);
		if(index === -1){
			this.confg.categories.push(id);
		}else{
			this.confg.categories.splice(index,1);
		}
	}

	changeLeadSource(id){
		var index = this.confg.lead_sources.indexOf(id);
		if(index === -1){
			this.confg.lead_sources.push(id);
		}else{
			this.confg.lead_sources.splice(index,1);
		}
	}

	changeLeadFields(id){
		var index = this.confg.lead_fields.indexOf(id);
		if(index === -1){
			this.confg.lead_fields.push(id);
		}else{
			this.confg.lead_fields.splice(index,1);
		}
	}

	changeShowed(id){
		if(this.showed === id){
			this.showed = 0;	
		}else{
			this.showed = id;
		}
	}
}